export class Coordinate {
	constructor(lat, lng) {
		this.lat = lat;
		this.lng = lng;
	}
}

// coordinates are 2 element arrays in the form [lat, lng]
// this is an approximate distance useful for sorting, which is fast
export const getApproxDistance = (coordinateA, coordinateB) => {
    let lat1 = coordinateA[0];
    let lat2 = coordinateB[0];
    let lng1 = coordinateA[1];
    let lng2 = coordinateB[1];
    let x = (lng2 - lng1) * Math.cos((lat1 + lat2) / 2);
    let y = (lat2 - lat1);
    return Math.sqrt(x * x + y * y); // assume radius of 1
}

// coordinates are 2 element arrays in the form [lat, lng]
export const getDistanceInMiles = (coordinateA, coordinateB) => {
    let disInM = _distance(coordinateA[0], coordinateA[1], coordinateB[0], coordinateB[1], DISTANCE_UNIT.M);
    //round to 2 decimal places
    // return Math.round(disInM * 100) / 100;
    // round to int
    return Math.round(disInM);
}

/*
unit: The unit of measurement in which to calculate the results where:
    'M' is statute miles (default)
    'K' is kilometers
    'N' is nautical miles
*/
export const DISTANCE_UNIT = {
    M: "M",
    K: "K",
    N: "N",
}

/* 
This version uses the Haversine formula as implemented by the GeoDataSource.com
Note that this version is slower than getApproxDistance

lat1, lon1: The Latitude and Longitude of point 1 (in decimal degrees)
lat2, lon2: The Latitude and Longitude of point 2 (in decimal degrees)
unit: The unit of measurement in which to calculate the results where:
    'M' is statute miles (default)
    'K' is kilometers
    'N' is nautical miles
*/
const _distance = (lat1, lon1, lat2, lon2, unit) => {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === DISTANCE_UNIT.K) { dist = dist * 1.609344; }
    if (unit === DISTANCE_UNIT.N) { dist = dist * 0.8684; }
    return dist; // assuming unit == DISTANCE_UNIT.M
}

export const getSortByOverallFunction = (sortAscending) => {
    return (sortAscending) ? (a, b) => _sortByTopHelper(a, b) : (a, b) => _sortByTopHelper(b, a);
}

// Combination of number of reviews from both yelp and google to rate a boba shop, shops with more
// reviews weights more with their rating
const _getPowerRating = (poi) => {
    return Math.pow(poi.general_rating, 2) * Math.pow(poi.general_reviews, 0.25);
}

const _sortByTopHelper = (a, b) => {
    return (_getPowerRating(a) / 2) - (_getPowerRating(b) / 2);
}

export const getSortByTitleFunction = (sortAscending) => {
    let dir = 1;
    if (sortAscending) {
        dir = -1;
    }
    return (a, b) => {
        let aTitle = a.general_title.toLowerCase();
        let bTitle = b.general_title.toLowerCase();
        if (aTitle < bTitle) return dir;
        if (aTitle > bTitle) return -dir;
        return 0;
    }
}



// Sort by rating first then reviews
const _sortHelperRatingThenReviews = (a, b) => {
    return a.general_rating !== b.general_rating ? b.general_rating - a.general_rating : b.general_reviews - a.general_reviews;
}

export const getSortByRatingFunction = (sortAscending) => {
    return (sortAscending) ? (a, b) => _sortHelperRatingThenReviews(a, b) : (a, b) => _sortHelperRatingThenReviews(b, a);
}


// Unlike the other sort functions,
// this function takes in an item with the "distance" value
export const getSortByLocationFunction = (sortAscending) => {
    return sortAscending ? (a, b) => a.distance - b.distance : (a, b) => b.distance - a.distance;
}
