import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core'

// Turn off autoAddCss for font awesome because there is an issue related to how 
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;

const Footer = () => {
    return (
        <footer className="p-4 page-footer font-small mdb-color darken-3">
            <div className="py-2">
                <div className="footer-copyright text-center py-1">
                    &copy; Bobacritic 2019
                </div>
                <div className="footer-copyright text-center py-1">
                    <span className="ml-2">
                        <a
                            className="link-yellow"
                            href="https://www.instagram.com/boba_critic/"
                            rel="noopener noreferrer"
                            target="_blank">
                            <FontAwesomeIcon className="rating-fa-svg" icon={faInstagram} />
                        </a>
                    </span>
                    <span className="ml-2">
                        <a
                            className="link-yellow"
                            href="https://www.fb.me/thebobacritic/"
                            rel="noopener noreferrer"
                            target="_blank">
                            <FontAwesomeIcon className="rating-fa-svg" icon={faFacebook} />
                        </a>
                    </span>
                    <span className="ml-2">
                        <a
                            className="link-yellow"
                            href="https://www.twitter.com/BobaCritic/"
                            rel="noopener noreferrer"
                            target="_blank">
                            <FontAwesomeIcon className="rating-fa-svg" icon={faTwitter} />
                        </a>
                    </span>
                </div>
            </div>
        </footer >
    )
}

export default Footer;
