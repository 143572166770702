
import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';

// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;


const reviewImageExists = (review) => {
    return !(review.place_photos === null || review.place_photos === undefined || review.place_photos.length === 0);
}


// e.g. date = "2019-11-09T06:03:39.979187Z"
// returns e.g. "11/8/2019"
const formatReviewCreatedDate = (date) => {
    return new Date(date).toLocaleDateString();
}

const ReviewListItem = ({ review }) => {
    return (
        <div className="card-body">
            <h6 className="card-title">{review.title}</h6>
            <p className="map-card-link card-text">
                <span className="mr-1"><FontAwesomeIcon className="details-fa-svg" icon={faStar} /></span>{review.main_rating}
                <span className="review-list-item-date text-secondary">{formatReviewCreatedDate(review.date_created)}</span>
            </p>
            <p className="card-text">{review.body}</p>

            {reviewImageExists(review) && <ReviewImageContainer review={review} />}

            <div className="card-link text-muted"><span className="mr-1"><FontAwesomeIcon className="review-list-item-fa-svg" icon={faThumbsUp} /></span>Like</div>
        </div>
    );
}

const ReviewImageContainer = ({ review }) => {
    const images = review.place_photos.map((src, index) => {
        return <ImageComponent key={index} imageSrc={src} />;
    });

    const getComponent = (review) => {
        if (review.place_photos.length === 0) {
            return (
                <div>
                </div>
            );
        } else if (review.place_photos.length === 1) {
            const imageSrc = review.place_photos;
            return (
                <div className="review-img-row row text-center text-lg-left">
                    <div className="col-12">
                        <a href={imageSrc} className="d-block mb-4 h-100">
                            {/* <img className="img-fluid" src={imageSrc} alt="review image" /> */}
                            <div className="square" style={{ backgroundImage: "url(" + imageSrc + ")" }}></div>
                        </a>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="review-img-row row text-center text-lg-left">
                    {images}
                </div>
            );
        }
    }

    // return getComponent(review);

    return (
        <div className="review-img-row row text-center text-lg-left">
            {images}
        </div>
    );
}

const ImageComponent = ({ imageSrc }) => {
    return (
        <div className="col-lg-4 col-md-4 col-xs-4 col-4 px-1">
            {/* <a href={imageSrc} className="d-block"> */}
            <a className="d-block">
                {/* <img className="img-fluid" src={imageSrc} alt="review image" /> */}
                <div className="square" style={{ backgroundImage: "url(" + imageSrc + ")" }}></div>
            </a>
        </div>
    );
}

export default ReviewListItem;
