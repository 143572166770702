import React from "react"

import Layout from "../components/layout"
import BobaList from "../components/boba-list"
import UserContext from "../components/user-context";

const MainPage = () => {
	return (
		<Layout>
			<UserContext.Consumer>
				{(userContext) => (
					<BobaList userContext={userContext} />
				)}
			</UserContext.Consumer>
		</Layout>
	)
}

export default MainPage;
