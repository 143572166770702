import axios from 'axios';
import { Coordinate } from "./sort-util";
import { hashCodeToRating, hashCodeToNumOfReviews, hashCodeToImg, hashCodeToRecommendation, hashCodeToPhoneNumber } from './fake-data-util';
import { GET_BOBA_LIST_RADIUS, GET_BOBA_LIST_MAX_NUMBER } from "../constants";
import { isBlank } from "./util";

// REACT_APP_ENV is our own environment. Both of these need to be set to production to enable production.
const USE_PROD_ENVIRONMENT = process.env.REACT_APP_ENV === 'production' && process.env.NODE_ENV === 'production';

/*
prod environment
api.bobacritic.com

staging environment
api.dev.bobacritic.com
*/
// TODO: use environmental variable here instead
export const getBobacriticApiUrl = () => {
	if (USE_PROD_ENVIRONMENT) {
		// return "https://api.bobacritic.com";
		// TODO make this the real prod.
		return "https://api.dev.bobacritic.com";
	} else if (process.env.REACT_APP_ENV === 'test') {
		return "https://api.dev.bobacritic.com";
	} else if (process.env.REACT_APP_ENV === 'development') {
		return "http://127.0.0.1:8000"
	} else {
		throw new Error("Invalid build. Please check build settings.");
	}
}

/*
prod facebook
2437027066387272

dev facebook
2281125525349922
*/
// TODO: use environmental variable here instead
export const getFacebookAppId = () => {
	if (USE_PROD_ENVIRONMENT) {
		// TODO make this the real prod.
		// return "2437027066387272";
		return "2281125525349922";
	} else if (process.env.REACT_APP_ENV === 'test') {
		return "2281125525349922";
	} else if (process.env.REACT_APP_ENV === 'development') {
		return "604247743758702";
	} else {
		throw new Error("Invalid build. Please check build settings.");
	}
}

/*
Creating a new boba place:

res.data.created_place =
{
	place_photos: [file0, file1],
	thumbnail_filename: [file0_name],
	place_data: {
		"general_title" : "Test Title",
		"general_description" : "Description Goes here",
		"general_address" : "123 My Address, Fake Street, San Francisco",
		"general_phone_number" : "",
		"geo_geometry_latitude" : 37.72818,
		"geo_geometry_longitude" : -122.47702
	}
}

If there are invalid parameters an error message is sent back in:
res.data.error

*/
export const postCreatePlace = (data, userID, accessToken) => {
	return new Promise((resolve, reject) => {
		axios.post(
			`${getBobacriticApiUrl()}/api/place`,
			data,
			{
				headers:
				{
					"Content-Type": "multipart/form-data",
					"FACEBOOK-USER-ID": userID,
					"FACEBOOK-USER-TOKEN": accessToken
				}
			})
			.then(res => {
				let data = res.data;
				console.log(res.data);
				if (!data) return reject(new Error("result contains no data"));

				if (!data.created_place) return reject(new Error(data.error));

				resolve(data);
			})
			.catch(err => reject(err));
	});
}

/*
Creates a new review for a boba place:

post request body:
{
	parent_place: <boba_place_id>
	title: <review_title>
	body: <review_body>
	main_rating: <review_rating>
	place_photos: []
}

post response:
{
	created_photos: [{ resource_path: "//images.dev.bobacritic.com/f837fdc8-42de-4976-812…a9ef46a5/e84d6ba7-f775-4820-8b7c-8ee6fc72bc34.jpg", uuid: "e84d6ba7-f775-4820-8b7c-8ee6fc72bc34" }]
	created_review: {
		body: "hello world"
		date_created: "2019-12-01T10:19:33.988988Z"
		date_updated: null
		main_rating: 5
		owner_user: { username: "_fb3350241468382157" }
		parent_place: { uuid: "f837fdc8-42de-4976-812a-fa785a032e28" }
		place_photos: []
		title: "new review 2"
		uuid: "40081c76-4cf5-4017-8f77-ef7fa9ef46a5"
	},
	"status": "SUCCESS"
}

 */
export const postWriteReview = (data, userID, accessToken) => {
	return new Promise((resolve, reject) => {
		axios.post(
			`${getBobacriticApiUrl()}/api/review`,
			data,
			{
				headers:
				{
					"Content-Type": "multipart/form-data",
					"FACEBOOK-USER-ID": userID,
					"FACEBOOK-USER-TOKEN": accessToken
				}
			})
			.then(res => {
				let data = res.data;

				if (!data) return reject(new Error("result contains no data"));

				resolve(data);
			})
			.catch(err => reject(err));
	});
}

/*
Edits an existing review of a boba place
Note that image modification isn't supported yet

patch request body:
{
	title: <review_title>
	body: <review_body>
	main_rating: <review_rating>
	place_photos: []
}

*/
export const patchEditReview = (data, reviewId, userID, accessToken) => {
	console.log(data, reviewId, userID, accessToken);
	return new Promise((resolve, reject) => {
		axios.patch(
			`${getBobacriticApiUrl()}/api/review/${reviewId}`,
			data,
			{
				headers:
				{
					"Content-Type": "application/json",
					"FACEBOOK-USER-ID": userID,
					"FACEBOOK-USER-TOKEN": accessToken
				}
			})
			.then(res => {
				let data = res.data;

				if (!data) return reject(new Error("result contains no data"));

				resolve(data);
			})
			.catch(err => reject(err));
	});
}

/**
Example data:

{
	"pois": [
		{
			date_created: "2019-11-04T05:10:13.507849Z"
			date_updated: null
			delivery_caviar: null
			delivery_doordash: "https://www.grubhub.com/restaurant/bing--boba-1476-haight-st-san-francisco/958204"
			delivery_grubhub: null
			delivery_postmates: "https://postmates.com/merchant/bing-boba-sf"
			delivery_ubereats: "https://www.ubereats.com/en-US/san-francisco/food-delivery/bing-%26-boba/SCwdSBrvQw65QiMAxt4rqw/?promo=34deeddb-3030-4e39-8e45-a3ccc561fc23"
			disabled: false
			general_address: "1476 Haight St, San Francisco, California 94117"
			general_description: "Boba Shop"
			general_hours_friday_end: "20:00:00"
			general_hours_friday_start: "12:00:00"
			general_hours_monday_end: null
			general_hours_monday_start: null
			general_hours_saturday_end: "20:00:00"
			general_hours_saturday_start: "12:00:00"
			general_hours_sunday_end: "19:00:00"
			general_hours_sunday_start: "12:00:00"
			general_hours_thursday_end: "19:00:00"
			general_hours_thursday_start: "12:00:00"
			general_hours_tuesday_end: "19:00:00"
			general_hours_tuesday_start: "12:00:00"
			general_hours_wednesday_end: "19:00:00"
			general_hours_wednesday_start: "12:00:00"
			general_phone_number: "xxx-xxx-xxxx"
			general_rating: 5
			general_recommended: false
			general_reviews: 190
			general_reviews_count: 0
			general_thumbnail_link: "general_thumbnail_link": {
				"resource_path": "//images.dev.bobacritic.com/2b4430da-95d3-41a5-9d80-7a3bc98e2f5c/c7647932-3cd1-4630-b785-aa950feff8cf.jpg",
				"uuid": "c7647932-3cd1-4630-b785-aa950feff8cf",
				"parent_place": {
					"uuid": "2b4430da-95d3-41a5-9d80-7a3bc98e2f5c"
				},
				"parent_review": null
			}
			general_title: "Bing and Boba"
			geo_geometry_latitude: 37.77016
			geo_geometry_longitude: -122.44668
			sources_bobacritic_main_rating: null
			sources_bobacritic_rating: null
			sources_google_link: "https://www.google.com/search?sxsrf=ACYBGNS1-ed9xg8RavXH8m9wL-dvqK6rlQ%3A1568181259022&ei=C4x4XYaCAYiAtgWXx444&q=Bing+and+Boba+1476+Haight+St%2C+San+Francisco%2C+California+94117&oq=Bing+and+Boba+1476+Haight+St%2C+San+Francisco%2C+California+94117&gs_l=psy-ab.3..38.7436.7771..8170...0.2..0.122.227.0j2......0....1j2..gws-wiz.......0i71j0j0i22i30.cB9mICD5EZw&ved=0ahUKEwjGs4y3isjkAhUIgK0KHZejAwcQ4dUDCAs&uact=5"
			sources_yelp_link: "https://www.yelp.com/biz/bing-and-boba-san-francisco?osq=Bing+and+Boba"
			uuid: "2b4430da-95d3-41a5-9d80-7a3bc98e2f5c"
		}
	],
	"status": "SUCCESS"
}

 */


// Use SF as the default location
export const getBobaListRestCall = (coordinate) => {
	const url = `${getBobacriticApiUrl()}/api/places?lat=${coordinate.lat}&lng=${coordinate.lng}&radius=${GET_BOBA_LIST_RADIUS}&max_num=${GET_BOBA_LIST_MAX_NUMBER}`;
	return new Promise((resolve, reject) => {
		axios.get(url)
			.then(res => {
				let data = res.data;
				if (!data) return reject(new Error("result contains no data"));

				if (data.pois) {
					data.pois.forEach(poi => {
						// poi["general_rating"] = hashCodeToRating(poi.general_title);
						// poi["general_reviews_count"] = hashCodeToNumOfReviews(poi.general_title);
						// poi["general_phone_number"] = hashCodeToPhoneNumber(poi.general_title);
						poi["general_recommended"] = hashCodeToRecommendation(poi.general_title);
						// poi["general_thumbnail_link"] = hashCodeToImg(poi.general_title);
					});
				} else {
					data.pois = [];
				}

				resolve(data);
			})
			.catch(err => reject(err));
	});
};


/*
Example data:

{
	"poi_id": "2b4430da-95d3-41a5-9d80-7a3bc98e2f5c",
	"all_reviews": [],
    "user_reviews": [
        {
            "uuid": "1c3e55c0-d830-4fa6-82de-215ce414c7a7",
            "parent_place": {
                "uuid": "2b4430da-95d3-41a5-9d80-7a3bc98e2f5c"
            },
            "title": "Best oolong milktea I've ever had!",
            "body": "Enough said, this place is absolutely amazing, best oolong milktea I've ever had!",
            "main_rating": 3.0,
            "place_photos": [
                {
                    "resource_path": "//images.dev.bobacritic.com/2b4430da-95d3-41a5-9d80-7a3bc98e2f5c/1c3e55c0-d830-4fa6-82de-215ce414c7a7/99ef239e-a5cb-4c7e-9654-937a212fa2f2.jpg",
                    "uuid": "99ef239e-a5cb-4c7e-9654-937a212fa2f2",
                    "parent_place": {
                        "uuid": "2b4430da-95d3-41a5-9d80-7a3bc98e2f5c"
                    },
                    "parent_review": {
                        "uuid": "1c3e55c0-d830-4fa6-82de-215ce414c7a7"
                    }
                },
                {
                    "resource_path": "//images.dev.bobacritic.com/2b4430da-95d3-41a5-9d80-7a3bc98e2f5c/1c3e55c0-d830-4fa6-82de-215ce414c7a7/d631efb2-6b3e-46d4-bc2a-1ed4f45abaa0.jpg",
                    "uuid": "d631efb2-6b3e-46d4-bc2a-1ed4f45abaa0",
                    "parent_place": {
                        "uuid": "2b4430da-95d3-41a5-9d80-7a3bc98e2f5c"
                    },
                    "parent_review": {
                        "uuid": "1c3e55c0-d830-4fa6-82de-215ce414c7a7"
                    }
                }
            ],
            "date_created": "2019-11-10T10:57:39.927776Z",
            "date_updated": null,
            "owner_user": {
                "username": "_fb3350241468382157"
            }
        }
	],
	"status": "SUCCESS"
}

 */
export const getReviewsRestCall = (poi_id, userID, accessToken) => {
	let config = {};
	// If userId and accessToken are empty then we can only get the user other's reviews,
	// if they are present, we can get the users reveiws and other's reviews
	if (!isBlank(userID) && !isBlank(accessToken)) {
		config = {
			headers:
			{
				"Content-Type": "application/json",
				"FACEBOOK-USER-ID": userID,
				"FACEBOOK-USER-TOKEN": accessToken
			}
		};
	}
	return new Promise((resolve, reject) => {
		axios.get(`${getBobacriticApiUrl()}/api/placereviews/${poi_id}?max_num=200`, config)
			.then(res => {
				let data = res.data;
				if (!data) reject(new Error("result contains no data"));
				else resolve(data);
			})
			.catch(err => reject(err));
	});
};

/*

Get all photos of a place, including photos from reviews 

Example data:

{
    "uuid": "b27c0ffb-313d-4df4-8773-7328a9451976",
    "general_thumbnail_link": {
        "resource_path": "//images.dev.bobacritic.com/b27c0ffb-313d-4df4-8773-7328a9451976/104a2f8b-c11e-41fb-846c-8962f42c8b6b.jpg",
        "uuid": "104a2f8b-c11e-41fb-846c-8962f42c8b6b"
    },
    "place_only_photos": [
        {
            "resource_path": "//images.dev.bobacritic.com/b27c0ffb-313d-4df4-8773-7328a9451976/38fa64a1-11b8-4dda-a3b2-4548fe3d90a8.jpg",
            "uuid": "38fa64a1-11b8-4dda-a3b2-4548fe3d90a8"
        }
    ],
    "review_photos": [
        {
            "resource_path": "//images.dev.bobacritic.com/b27c0ffb-313d-4df4-8773-7328a9451976/38fa64a1-11b8-4dda-a3b2-4548fe3d90a8.jpg",
            "uuid": "38fa64a1-11b8-4dda-a3b2-4548fe3d90a8"
		}
    ],
    "status": "SUCCESS"
}

 */
export const getPlacePhotosRestCall = (poi_id) => {
	return new Promise((resolve, reject) => {
		axios.get(`${getBobacriticApiUrl()}/api/place_photos/${poi_id}`)
			.then(res => {
				let data = res.data;
				if (!data) reject(new Error("result contains no data"));
				else resolve(data);
			})
			.catch(err => reject(err));
	});
};

/*
Creates a new review for a boba place:

post request body:
{
	place_data: {"parent_place":"{{SAMPLE_POI_UUID}}"},
	place_photos: []
}

post response:
{

	"status": "SUCCESS"
}

 */
export const postCreatePlacePhotosRestCall = (data, userID, accessToken) => {
	return new Promise((resolve, reject) => {
		axios.post(
			`${getBobacriticApiUrl()}/api/place_photos`,
			data,
			{
				headers:
				{
					"Content-Type": "multipart/json",
					"FACEBOOK-USER-ID": userID,
					"FACEBOOK-USER-TOKEN": accessToken
				}
			})
			.then(res => {
				let data = res.data;
				console.log(data);
				if (!data) return reject(new Error("result contains no data"));

				resolve(data);
			})
			.catch(err => reject(err));
	});
}

/*

Get a place details 

Example data:

{
    "poi": {
        "general_thumbnail_link": {
            "resource_path": "//images.dev.bobacritic.com/2b4430da-95d3-41a5-9d80-7a3bc98e2f5c/c7647932-3cd1-4630-b785-aa950feff8cf.jpg",
            "uuid": "c7647932-3cd1-4630-b785-aa950feff8cf"
        },
        "uuid": "2b4430da-95d3-41a5-9d80-7a3bc98e2f5c",
        "general_title": "Bing and Boba",
        "general_description": "Boba Shop",
        "general_address": "1476 Haight St, San Francisco, California 94117",
        "general_phone_number": null,
        "general_recommended": false,
        "geo_geometry_latitude": 37.77016,
        "geo_geometry_longitude": -122.44668,
        "sources_yelp_link": "https://www.yelp.com/biz/bing-and-boba-san-francisco?osq=Bing+and+Boba",
        "sources_google_link": "https://www.google.com/search?sxsrf=ACYBGNS1-ed9xg8RavXH8m9wL-dvqK6rlQ%3A1568181259022&ei=C4x4XYaCAYiAtgWXx444&q=Bing+and+Boba+1476+Haight+St%2C+San+Francisco%2C+California+94117&oq=Bing+and+Boba+1476+Haight+St%2C+San+Francisco%2C+California+94117&gs_l=psy-ab.3..38.7436.7771..8170...0.2..0.122.227.0j2......0....1j2..gws-wiz.......0i71j0j0i22i30.cB9mICD5EZw&ved=0ahUKEwjGs4y3isjkAhUIgK0KHZejAwcQ4dUDCAs&uact=5",
        "sources_bobacritic_rating": null,
        "sources_bobacritic_main_rating": null,
        "general_hours_monday_start": null,
        "general_hours_monday_end": null,
        "general_hours_tuesday_start": "12:00:00",
        "general_hours_tuesday_end": "19:00:00",
        "general_hours_wednesday_start": "12:00:00",
        "general_hours_wednesday_end": "19:00:00",
        "general_hours_thursday_start": "12:00:00",
        "general_hours_thursday_end": "19:00:00",
        "general_hours_friday_start": "12:00:00",
        "general_hours_friday_end": "20:00:00",
        "general_hours_saturday_start": "12:00:00",
        "general_hours_saturday_end": "20:00:00",
        "general_hours_sunday_start": "12:00:00",
        "general_hours_sunday_end": "19:00:00",
        "delivery_caviar": null,
        "delivery_doordash": "https://www.grubhub.com/restaurant/bing--boba-1476-haight-st-san-francisco/958204",
        "delivery_grubhub": null,
        "delivery_ubereats": "https://www.ubereats.com/en-US/san-francisco/food-delivery/bing-%26-boba/SCwdSBrvQw65QiMAxt4rqw/?promo=34deeddb-3030-4e39-8e45-a3ccc561fc23",
        "delivery_postmates": "https://postmates.com/merchant/bing-boba-sf",
        "date_created": "2019-11-04T05:10:13.507849Z",
        "date_updated": null,
        "general_rating": 3.14814814814814,
        "general_reviews_count": 27,
        "disabled": false
	},
	"status": "SUCCESS"
}
 */
export const getPlaceRestCall = (poi_id) => {
	return new Promise((resolve, reject) => {
		axios.get(`${getBobacriticApiUrl()}/api/place/${poi_id}`)
			.then(res => {
				let data = res.data;
				if (!data) reject(new Error("result contains no data"));
				else resolve(data);
			})
			.catch(err => reject(err));
	});
};


/*

Get user info after FB login

Example data:

{
    "username": "_fb3350241468382157",
    "display_name": "_fb3350241468382157",
    "fb_user_pk": {
        "fb_user_id": "3350241468382157"
    },
    "user_info": {
        "full_name": "Alex Ouyang",
        "favorite_places": []
	},
	permissions: [
		"can_add_reviews", 
		"can_create_pois",
		"can_delete_reviews",
		"can_edit_pois",
		"can_edit_reviews",
		"can_view_pois"
	],
    "email": "",
	"rpg_info": 3,
	"status": "SUCCESS"
}

*/
export const getUserRestCall = (userID, accessToken) => {
	return new Promise((resolve, reject) => {
		axios.get(`${getBobacriticApiUrl()}/api/user`,
			{
				headers:
				{
					"Content-Type": "application/json",
					"FACEBOOK-USER-ID": userID,
					"FACEBOOK-USER-TOKEN": accessToken
				}
			})
			.then(res => {
				let data = res.data;
				if (!data) reject(new Error("result contains no data"));
				else resolve(data);
			})
			.catch(err => reject(err));
	});
};


/*

If the place doesn't exist:

{
  "type": "FeatureCollection",
  "licence": "Data © OpenStreetMap contributors, ODbL 1.0. https://osm.org/copyright",
  "features": [
    
  ],
  "status": "SUCCESS"
}

If the place exists:


// 20191116183535
// https://nominatim.openstreetmap.org/search?q=%222815%20California%20St,%20San%20Francisco,%20CA%2094115%22&format=geojson

{
  "type": "FeatureCollection",
  "licence": "Data © OpenStreetMap contributors, ODbL 1.0. https://osm.org/copyright",
  "features": [
    {
      "type": "Feature",
      "properties": {
        "place_id": 56891494,
        "osm_type": "node",
        "osm_id": 4584919126,
        "display_name": "2815, California Street, Lower Pacific Heights, San Francisco, California, 94115, United States of America",
        "place_rank": 30,
        "category": "place",
        "type": "house",
        "importance": 0.731
      },
      "bbox": [
        -122.440725,
        37.7878517,
        -122.440625,
        37.7879517
      ],
      "geometry": {
        "type": "Point",
        "coordinates": [
          -122.440675,
          37.7879017
        ]
      }
    }
  ]
}

In some cases, place with multiple features might show up, which is bad.....
e.g. "714 Kearny St, San Francisco, CA 94111"

*/
export const getStreetAddressGeoEncodingRestCall = async address => {
	try {
		const res = await axios.get(`https://nominatim.openstreetmap.org/search?q="${address}"&format=geojson`);
		const result = res.data;
		if (!result) {
			new Error("result contains no data")
		}
		const features = result.features;
		if (!features) {
			new Error("result contains no features")
		}

		if (features.length > 1) {
			// TODO - need to figure out which coordinate to use
			console.error("Street address has many features, need to use the coordinate conversion tool below to get latitude and longitude manually");
		}

		return features;
	} catch (err) {
		console.error(err)
	}
};


