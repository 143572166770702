import React from "react";
import { Redirect, Route } from "react-router-dom";

import UserContext, { LOGIN_STATUS } from "../user-context";

/**
 * For PrivateRoute redirect to work we need to wait until user data is fetched then evaluate whether we should allow user
 * to go to the private component or redirect the user to login page for authentication
 */
const PrivateRouteWaitForData = ({ component: Component, redirectPath, userContext, ...rest }) => {
    // If login failed, redirect to login
    // If login succeeded and was able to fetch user data, if user data contains permission to create poi go to poi submission page otherwise rediect to login
    if (userContext.state.loginStatus === LOGIN_STATUS.FAILURE ||
        userContext.state.loginStatus === LOGIN_STATUS.FETCHED_USER_SUCCESS) {
        return (
            <div>
                <Route
                    {...rest}
                    render={
                        props => userContext.canCreatePois() ?
                            <Component {...props} /> :
                            <Redirect to={{
                                pathname: redirectPath,
                                state: { from: props.location }
                            }} />
                    }
                />
            </div>
        );
    }

    // Show a blank page while waiting for the user to get login status and user data
    return <div></div>;
}

/**
 * Use it normally like a Route, but you can specify a redirect path to redirect to if user has no permission
 * e.g. 				
 * <PrivateRoute path="/poi-submission-form" redirectPath={"/login"} component={PoiSubmissionForm} />
 */
const PrivateRoute = ({ ...rest }) => {
    return (
        <div>
            <UserContext.Consumer>
                {(userContext) => (
                    <PrivateRouteWaitForData {...rest} userContext={userContext} />
                )}
            </UserContext.Consumer>
        </div>
    )
}

export default PrivateRoute;