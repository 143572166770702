import React from 'react';

import "../styles/loading-panel.css"

import UserContext from "../components/user-context";


const LoadingComponent = () => {
    return (
        <div id="loading-panel">
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div >
    )
}

const LoadingPanel = () => {
    return (
        <div>
            <UserContext.Consumer>
                {(userContext) => (
                    userContext.state.showLoadingPanel && <LoadingComponent />
                )}
            </UserContext.Consumer>
        </div>
    )
}

export default LoadingPanel;