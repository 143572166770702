import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { getFacebookAppId } from "./utils/rest-util";

class FacebookLogin extends Component {
    constructor(props) {
        super(props)

        // this.appId = getFacebookAppId();
        // console.log("facebook app id: " + this.appId);

        // this.loadSDK = this.loadSDK.bind(this);
        // this.statusChangeCallback = this.statusChangeCallback.bind(this);
        this.facebookLogoutClicked = this.facebookLogoutClicked.bind(this);
        this.facebookLogoutCallback = this.facebookLogoutCallback.bind(this);
        this.facebookLoginClicked = this.facebookLoginClicked.bind(this);
    }

    // loadSDK() {
    //     window.fbAsyncInit = function () {
    //         window.FB.Event.subscribe('auth.authResponseChange', this.statusChangeCallback);
    //         window.FB.init({
    //             appId: this.appId,
    //             cookie: true,
    //             xfbml: false, // We will use our own styling.
    //             version: 'v4.0',
    //             status: true
    //         });
    //         window.FB.AppEvents.logPageView();

    //         // In your onload method:
    //         console.log("Facebook SDK loaded...");
    //     }.bind(this);
    //     const js = window.document.createElement('script');
    //     js.id = 'facebook-jssdk';
    //     js.async = true;
    //     js.defer = true;
    //     js.src = "https://connect.facebook.net/en_US/sdk.js";
    //     window.document.body.appendChild(js);
    // }

    facebookLogoutClicked() {
        console.log("logging out of Facebook");
        // Update auth after logging out
        window.FB.logout(this.facebookLogoutCallback);
    }

    facebookLogoutCallback(response) {
        this.props.userContext.updateAuthResponse(null);
        console.log("user is logged in: " + this.props.userContext.userAuthenticated())
        this.deleteCookie("fblo_" + getFacebookAppId()); // fblo_yourFBAppId. example: fblo_444499089231295
    }

    // this doesn't seem to be working
    deleteCookie(name) {
        console.log("deleting cookie " + name);
        // This doens't seem to work
        // document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    facebookLoginClicked() {
        window.FB.login();
    }

    // This is called whenever sdk is loaded, FB.login or FB.lotout is called
    // statusChangeCallback(response) {
    //     console.log('statusChangeCallback');
    //     if (response.status === 'connected') {
    //         console.log("User Logged in.");
    //         // Update user context auth response
    //         this.props.userContext.updateAuthResponse(response.authResponse);
    //         console.log("user is logged in: " + this.props.userContext.userAuthenticated())
    //     } else if (response.status === 'not_authorized') {
    //         console.warn("User is not logged into this app.");
    //     } else if (response.status === "unknown") {
    //         console.warn("Login status is unknown...");
    //     }
    // }

    componentDidMount() {
        // Automatic login when login page is loaded
        // this.loadSDK();

        // This is needed since private route may redirect back to login and befor that 
        // redirection the app is stuck in the loading screen
        // setTimeout(() => {
        //     this.props.userContext.setShowLoadingPanel(false);
        // }, 2000);
    }

    getFacebookButton() {
        if (this.props.userContext.userAuthenticated()) {
            return (
                <button
                    type="button"
                    className="fb-login-button btn-custom btn btn-primary"
                    onClick={this.facebookLogoutClicked}
                >
                    <FontAwesomeIcon className="rating-fa-svg pr-2" icon={faFacebookSquare} />
                    Logout of Facebook
                </button>
            );
        } else {
            return (
                <button
                    type="button"
                    className="fb-login-button btn-custom btn btn-primary"
                    onClick={this.facebookLoginClicked}
                >
                    <FontAwesomeIcon className="rating-fa-svg pr-2" icon={faFacebookSquare} />
                    Login with Facebook
                </button>
            );
        }
    }

    render() {
        return (
            <div>
                {this.getFacebookButton()}
            </div>
        );
    }
}

export default FacebookLogin;
