import { Coordinate } from "./utils/sort-util";

// Used to check if user is on mobile
export const MOBILE_WIDTH = 750;

// Use SF as the default location
export const SAN_FRANCISCO_COORDINATE = new Coordinate(37.7749, -122.4194);

// Search radius of 2000 is about 10 miles
export const GET_BOBA_LIST_RADIUS = 4000;
// Max number of boba returned
export const GET_BOBA_LIST_MAX_NUMBER = 500;

// Show the "search here" button after minimum miles the center of the map has moved
export const SHOW_SEARCH_HERE_BUTTON_MINIMUM_MILES = 5;

// The max numnber of characters allowed in a review body
export const REVIEW_BODY_MAX_CHARS = 500;
