import React, { Component } from 'react';
import { getFacebookAppId, getUserRestCall } from "./utils/rest-util";
import { SAN_FRANCISCO_COORDINATE } from "./constants";
import { Coordinate } from "./utils/sort-util";
import axios from 'axios';

const UserContext = React.createContext();

/* 
this is the typically login flow:
NONE -> SUCCESS -> FETCHED_USER_SUCCESS
     -> FAILURE
*/
export const LOGIN_STATUS = {
  NONE: 0,
  FAILURE: 1, // failed to login
  SUCCESS: 2, // successfully logged in
  FETCHED_USER_SUCCESS: 3, // fetched user data after successfully logged in
}

class UserProvider extends Component {

  constructor(props) {
    super(props)

    // access token and user id should be populated once user logged in through facebook
    this.state = {
      userName: "",
      permissions: [], // ["add_bobacriticuser", "can_add_reviews", "can_create_pois", "can_delete_reviews", "can_edit_pois", "can_edit_reviews", "can_super_delete_reviews", "can_view_pois"]
      loginStatus: LOGIN_STATUS.NONE, // this is set after calling statusChangeCallback on fb sdk, which results in either login success or failure
      authResponse: undefined, // {accessToken, userID}
      searchCoordinate: SAN_FRANCISCO_COORDINATE, // Default search position in lat and lng, must be of type Coordinate
      poiList: undefined,
      showLoadingPanel: false,
    }

    this.loadSDK = this.loadSDK.bind(this);
    this.statusChangeCallback = this.statusChangeCallback.bind(this);
  }

  componentDidMount() {
    // Automatic login when login page is loaded
    this.loadSDK();
  }

  loadSDK() {
    this.setShowLoadingPanel(true);

    // let initFB = new Promise((resolve, reject) => {

    //   window.fbAsyncInit = function () {
    //     window.FB.init({
    //       appId: getFacebookAppId(),
    //       cookie: true,
    //       xfbml: false, // We will use our own styling.
    //       version: 'v4.0',
    //       status: true
    //     });
    //     window.FB.AppEvents.logPageView();

    //     window.FB.getLoginStatus((response) => this.statusChangeCallback(response, resolve));

    //     // In your onload method:
    //     console.log("Facebook SDK loaded...");

    //   }.bind(this);

    // })
    // .then(res => {
    // })


    window.fbAsyncInit = function () {
      window.FB.init({
        appId: getFacebookAppId(),
        cookie: true,
        xfbml: false, // We will use our own styling.
        version: 'v4.0',
        status: true
      });

      window.FB.AppEvents.logPageView();

      window.FB.getLoginStatus(this.statusChangeCallback);

      console.log("Facebook SDK loaded...");

    }.bind(this);

    const js = window.document.createElement('script');
    js.id = 'facebook-jssdk';
    js.async = true;
    js.defer = true;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    window.document.body.appendChild(js);
  }

  // This is called whenever sdk is loaded, FB.login or FB.lotout is called
  statusChangeCallback(response) {
    console.log('statusChangeCallback', response.status);
    if (response.status === 'connected') {
      this.setState({ loginStatus: LOGIN_STATUS.SUCCESS });

      console.log("User Logged in.");
      const authResponse = response.authResponse;
      
      console.log(authResponse);

      this.setState({ authResponse })

      // Ge user info after authentication
      this.getUserInfo(authResponse.userID, authResponse.accessToken);

      // console.log(response.authResponse);
    } else {
      this.setState({ loginStatus: LOGIN_STATUS.FAILURE });

      this.setShowLoadingPanel(false);

      // if (response.status === 'not_authorized') {
      //   console.log("User is not logged into this app.");
      // } else if (response.status === "unknown") {
      //   console.log("Login status is unknown...");
      // }
    }
  }

  getUserInfo(userID, accessToken) {
    getUserRestCall(userID, accessToken)
      .then((data) => {
        if (data.user_info && data.user_info.full_name) {
          this.setState({ userName: data.user_info.full_name });
          // console.log("user: ", data);
        } else {
          console.error("User info dosn't have user name");
        }

        this.setState({ permissions: data.permissions.flat() });
        this.setState({ loginStatus: LOGIN_STATUS.FETCHED_USER_SUCCESS });
        console.log("LOGIN_STATUS.FETCHED_USER_SUCCESS");
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.setShowLoadingPanel(false);
      })
  }

  setShowLoadingPanel(show) {
    this.setState({ showLoadingPanel: show });
    if (show) {
      // Prevent scrolling
      // document.body.classList.add("no-sroll");
      document.body.style.overflow = "hidden";

    } else {
      // document.body.classList.remove("no-sroll");
      // Enable scrolling
      document.body.style.overflow = "auto";
    }
  }

  render() {
    return (
      <UserContext.Provider value={{
        state: this.state,
        userAuthenticated: () => {
          return this.state.authResponse != null;
        },
        canAddReviews: () => {
          return this.state.permissions.includes("can_add_reviews");
        },
        canEditReviews: () => {
          return this.state.permissions.includes("can_edit_reviews");
        },
        canCreatePois: () => {
          console.log(this.state.permissions);
          return this.state.permissions.includes("can_create_pois");
        },
        updateAuthResponse: (authResponse) => {
          this.setState({ authResponse })
          console.log("Saved fb auth response to user context: ", this.state.authResponse);
        },
        updateSearchCoordinate: (searchCoordinate) => {
          this.setState({ searchCoordinate });
          console.log("Saved user coordinate: ", searchCoordinate);
        },
        updatePoiList: (poiList) => {
          this.setState({ poiList })
          console.log("Saved poi list to user context: ", this.state.poiList.length);
        },
        // need to reset poi list to null whenever a new place is submitted, this will trigger a get request to fetch new lists
        resetPoiList: () => {
          this.setState({ poiList: null })
          console.log("Reset poi list in user context to null");
        },
        // show is a boolean
        setShowLoadingPanel: (show) => {
          this.setShowLoadingPanel(show);
        }
      }}>
        {this.props.children}
      </UserContext.Provider>
    )
  }
}

export { UserProvider };
export default UserContext;
