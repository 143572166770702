import { isNullOrUndefined } from './util';

class BusinessHours {
    constructor(date, start, width) {
        this.date = date; // Date of the week, e.g. "sunday"
        this.start = start;
        this.end = width;
    }

    get formattedString() {
        return this.toFormattedString();
    }

    // If start and end exists, returns a date time format like "12:00 am - 7:00 pm", otherwise returns "-"
    toFormattedString() {
        if (isNullOrUndefined(this.start) || isNullOrUndefined(this.end)) return "-";
        return `${this.start.formattedString} - ${this.end.formattedString}`;
    }

    // If dayHours exists, returns a date time format like ["12:00 am", "-", "7:00 pm"], else returns ["-"]
    toSpecialFormattedStringArray() {
        if (isNullOrUndefined(this.start) || isNullOrUndefined(this.end)) return ["-"];
        return [this.start.formattedString, "-", this.end.formattedString];
    }

    // Returns true if is in business right now
    isOpenRightNow() {
        if (isNullOrUndefined(this.start) || isNullOrUndefined(this.end)) return "";

        // Get date time for `now`
        let now = new Date();
        let h = now.getHours();
        let m = now.getMinutes();
        let nowMinute = h * 60 + m;

        let startingMinute = this.start.hour * 60 + this.start.minute;
        let endingMinute = this.end.hour * 60 + this.end.minute;
        return (startingMinute < nowMinute && nowMinute < endingMinute);
    }
}

class HourObj {
    constructor(hour, minute) {
        this.hour = hour;
        this.minute = minute;
    }

    get formattedString() {
        return this.toFormattedString();
    }

    // If hours and minute exists, returns a date time format like "12:30 pm" or "12:30 am", otherwise returns "";
    toFormattedString() {
        if (isNullOrUndefined(this.hour) || isNullOrUndefined(this.minute)) return "";

        let h = this.hour;
        let m = this.minute;

        let isAfterNoon = h >= 12;
        // e.g if hour is 13:00:00, it's actually 1 pm
        if (h > 12) h = h - 12;
        // e.g if hour is 00:00:00, it's actually 12 am
        else if (h === 0) h = 12;
        // Prepand 0 to minute if needed
        let formatedMinute = ("0" + m).slice(-2);
        let postFix = isAfterNoon ? "pm" : "am";

        return `${h}:${formatedMinute} ${postFix}`;
    }

    // hms is in the form of "12:00:00", we parse it to a HourObj
    // Note that we ignore the seconds as it is not used
    static parse(hms) {
        if (isNullOrUndefined(hms)) return null;

        let hmsParsed = hms.split(':');

        if (hmsParsed.length < 2) return null;
        if (isNaN(hmsParsed[0]) || isNaN(hmsParsed[1])) return null;

        return new HourObj(parseInt(hmsParsed[0]), parseInt(hmsParsed[1]));
    }
}

export const getOpenOrClosedString = businessHours => {
    return businessHours.isOpenRightNow() ? "Open" : "Closed";
}

export const WEEK_DAY = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const poiDataToWeeklyBusinessHours = (poi) => {
    if (!poi) return {};

    return {
        "sunday": new BusinessHours("sunday", HourObj.parse(poi.general_hours_sunday_start), HourObj.parse(poi.general_hours_sunday_end)),
        "monday": new BusinessHours("monday", HourObj.parse(poi.general_hours_monday_start), HourObj.parse(poi.general_hours_monday_end)),
        "tuesday": new BusinessHours("tuesday", HourObj.parse(poi.general_hours_tuesday_start), HourObj.parse(poi.general_hours_tuesday_end)),
        "wednesday": new BusinessHours("wednesday", HourObj.parse(poi.general_hours_wednesday_start), HourObj.parse(poi.general_hours_wednesday_end)),
        "thursday": new BusinessHours("thursday", HourObj.parse(poi.general_hours_thursday_start), HourObj.parse(poi.general_hours_thursday_end)),
        "friday": new BusinessHours("friday", HourObj.parse(poi.general_hours_friday_start), HourObj.parse(poi.general_hours_friday_end)),
        "saturday": new BusinessHours("saturday", HourObj.parse(poi.general_hours_saturday_start), HourObj.parse(poi.general_hours_saturday_end))
    };
}

// Accepts a Date object or date string that is recognized by the Date.parse() method
export const getDayOfWeek = () => {
    let dayOfWeek = new Date().getDay();
    return isNaN(dayOfWeek) ? null : WEEK_DAY[dayOfWeek];
}

export const debugPrintPoiBusinessHours = (poi) => {
    if (isNullOrUndefined(poi)) return;

    console.log("sunday: " + poi.general_hours_sunday_start + " - " + poi.general_hours_sunday_end);
    console.log("monday: " + poi.general_hours_monday_start + " - " + poi.general_hours_monday_end);
    console.log("tuesday: " + poi.general_hours_tuesday_start + " - " + poi.general_hours_tuesday_end);
    console.log("wednesday: " + poi.general_hours_wednesday_start + " - " + poi.general_hours_wednesday_end);
    console.log("thursday: " + poi.general_hours_thursday_start + " - " + poi.general_hours_thursday_end);
    console.log("friday: " + poi.general_hours_friday_start + " - " + poi.general_hours_friday_end);
    console.log("saturday: " + poi.general_hours_saturday_start + " - " + poi.general_hours_saturday_end);
}

