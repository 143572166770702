import React, { useState } from 'react';

import UserContext from "./user-context";
import { isBlank, compressImage } from "./utils/util";
import { postCreatePlacePhotosRestCall } from "./utils/rest-util";
import { SUCCESS } from '../components/utils/response-status';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';


// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;

// placePhotosData - look at getPlacePhotosRestCall documentation
// reloadPoiDetailsAfterReviewSubmission is basically StreetMapPage.reloadPoiDetailsAfterReviewSubmission
const PlacePhotosSection = ({ placePhotosData, reloadPoiDetailsAfterReviewSubmission}) => {

    const [imageFile, setImageFile] = useState(null);

    let place_only_photos = placePhotosData ? placePhotosData.place_only_photos : [];

    let review_photos = placePhotosData ? placePhotosData.review_photos : [];


    const PlaceOnlyImageComponent = place_only_photos.map((place_photo, index) => {
        return <ImageComponent key={index} imageSrc={place_photo.resource_path} />;
    });

    const ReviewPhotosComponent = review_photos.map((place_photo, index) => {
        return <ImageComponent key={index} imageSrc={place_photo.resource_path} />;
    });

    const handleSubmit = userContext => event => {
        event.preventDefault();

        if (!userContext.state.authResponse) {
            return;
        }

        if (!imageFile) {
            return;
        }

        userContext.setShowLoadingPanel(true);

        let imageFiles = [imageFile];

        Promise.all(imageFiles.map(i => compressImage(i)))
            .then((compressedFiles) => {
                let poiId = placePhotosData.uuid;
                if (poiId) {
                    sendPlacePhotoRequest(userContext, poiId, compressedFiles);
                } else {
                    console.error("Place photo data has no uuid", placePhotosData);
                }
            })
            .catch((error) => {
                console.error(error);
                userContext.setShowLoadingPanel(false);
            });
    }

    const sendPlacePhotoRequest = (userContext, poiId, imageFilesToUpload) => {

        const formData = new FormData();
        imageFilesToUpload.forEach(i => {
            formData.append("place_photos", i);
        });
        formData.append("place_data", JSON.stringify({
            parent_place: poiId
        }));

        const accessToken = userContext.state.authResponse.accessToken;
        const userID = userContext.state.authResponse.userID;

        postCreatePlacePhotosRestCall(formData, userID, accessToken)
            .then(res => {
                if (res.status === SUCCESS) {
                    reloadPoiDetailsAfterReviewSubmission();
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                userContext.setShowLoadingPanel(false);
            })
    }

    const handleSelectImageFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImageFile(event.target.files[0]);
        } else {
            // Handle set image canceled
            setImageFile(null);
        }
    }

    const addPhotoButton = (
        <div className="card-body">
            <div className="d-flex justify-content-center mb-3">
                <div className="custom-file">
                    <input
                        type="file"
                        accept="image/*"
                        className="custom-file-input"
                        id="firstImageFile"
                        onChange={handleSelectImageFile} />
                    <label className="custom-file-label" htmlFor="firstImageFile">{(imageFile && imageFile.name) ? imageFile.name : "Choose file"}</label>
                </div>
            </div>

            <UserContext.Consumer>
                {(userContext) => (
                    <button
                        type="button"
                        className="btn-custom btn btn-primary w-100"
                        onClick={handleSubmit(userContext)}
                    >
                        <FontAwesomeIcon className="rating-fa-svg pr-2" icon={faCamera} />Add Photo</button>
                )}
            </UserContext.Consumer>
        </div >
    );

    const photosList = (
        <div>
            {place_only_photos.length > 0 &&
                <div className="card-body pt-0">
                    {/* <h5 className="font-weight-bold">Gallery Photos</h5> */}

                    <div className="place-photos-row row text-center text-lg-left">
                        {PlaceOnlyImageComponent}
                    </div>
                </div>
            }
            {review_photos.length > 0 &&
                <div className="card-body review-border-top">
                    <h5 className="font-weight-bold">Review Photos</h5>
                    <div className="place-photos-row row text-center text-lg-left">
                        {ReviewPhotosComponent}
                    </div>
                </div>
            }
        </div>
    );

    const noPhotos = (
        <div className="card-body">
            <p className="mb-0 text-center">This place has no photos yet, help others out by submitting a review with photos!</p>
        </div>
    );

    // const photoSection = (place_only_photos.length === 0 && review_photos.length === 0) ? noPhotos : photosList;

    return (
        <div>
            {addPhotoButton}

            {photosList}
        </div>
    );
}

const ImageComponent = ({ imageSrc }) => {
    return (
        <div className="col-lg-4 col-md-4 col-xs-4 col-4 px-1 py-1">
            {/* <a href={imageSrc} className="d-block"> */}
            <div className="d-block">
                {/* <img className="img-fluid" src={imageSrc} alt="review image" /> */}
                <div className="square" style={{ backgroundImage: "url(" + imageSrc + ")" }}></div>
            </div>
        </div>
    );
}

export default PlacePhotosSection;
