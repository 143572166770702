import ReactGA from 'react-ga';
// https://github.com/Donaldcwl/browser-image-compression#readme
import imageCompression from 'browser-image-compression';

// This is google analytics tracking for boba list  view
export const initializeReactGA = (trackingPage) => {
	ReactGA.initialize('UA-146414983-1');
	ReactGA.pageview(trackingPage);
};

export const isNullOrUndefined = (x) => {
	return x === null || x === undefined;
}

// https://developers.google.com/maps/documentation/urls/guide
export const getGoogleMapSearchUrl = (title, address) => {
	return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(title)} ${encodeURIComponent(address)}`;
};

// Usage: <a href="tel:1-562-867-5309">1-562-867-5309</a>
export const getTelephoneLink = (phone_number) => {
	return `tel:${phone_number}`;
}

/*
example usage: 

getPosition()
  .then((location) => {
    console.log(location.coords.latitude, location.coords.longitude);
  })
  .catch((err) => {
    console.error(err.message);
  });
*/
export const getPosition = () => {
	return new Promise(function (resolve, reject) {
		if (!navigator.geolocation) {
			reject(new Error("Geolocation is not supported by this browser."));
		}

		navigator.geolocation.getCurrentPosition(resolve, reject);
	});
}

// round the number down to the closest 50
export const roundNumDownToCloset50 = (num) => {
	return Math.floor(num / 50) * 50;
};

/*
If num < 50, return that number, else, round the number down to the closest 50
example:
5 goes to 5
29 goes to 29
50-99 goes to 50+
100-149 goes to 100+
*/
export const roundRating = (num) => {
	return (num < 50) ? num : roundNumDownToCloset50(num) + "+";
};

// Remove everything after the last comma, 
// e.g. "714 Kearny St, San Francisco, CA 94111" => "714 Kearny St, San Francisco"
export const shortenAddress = (address) => {
	return address.substring(0, address.lastIndexOf(","));
}

// For checking if a string is blank, null or undefined
export const isBlank = (str) => {
	return (!str || /^\s*$/.test(str));
}

// If there are rating and reviews, display in the form of "5 (100)", otherwise display "No ratings or reviews"
export const getRatingReviewString = (poi) => {
	if (isNullOrUndefined(poi.general_rating) || isNullOrUndefined(poi.general_reviews_count)) {
		return "No ratings or reviews";
	}
	// Round rating to 1 decimal
	const roundedRating = Math.round(poi.general_rating * 10) / 10;
	return `${roundedRating} (${poi.general_reviews_count})`;
}


/**
 Compresses an image file
 */
const ImageCompressionOptions = {
	maxSizeMB: 1,
	maxWidthOrHeight: 512,
	useWebWorker: true
}
export const compressImage = (imageFile) => {

	console.log("originalFile name", imageFile.name, `originalFile size ${imageFile.size / 1024 / 1024} MB`);
	
	return new Promise((resolve, reject) => {
		imageCompression(imageFile, ImageCompressionOptions)
			.then((compressedBlob) => {
				let compressedFile = new File([compressedBlob], compressedBlob.name);

				if (!compressedFile instanceof File) {
					return reject(new Error(`Compressed file ${compressedBlob.name} is not a File`));
				}
				// Sometimes images actually get bigger after runing this compression algorithm, so use the smaller size one
				compressedFile = (compressedFile.size < imageFile.size) ? compressedFile : imageFile

				console.log("compressedFile name", imageFile.name, `compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

				resolve(compressedFile);
			})
			.catch(err => reject(err));
	});
}

