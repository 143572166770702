import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/global.css"

import PrivateRoute from "./components/utils/private-route";
import MainPage from "./containers/main-page";
import AboutPage from "./containers/about-page";
import LoginPage from "./containers/login-page";
import StreetMapPage from "./containers/street-map-page";
import PoiSubmissionForm from "./containers/poi-submission-form";
import StoreDetailsPage from "./containers/store-details-page";

import TestPage from "./containers/test-page";

import LoadingPanel from "./components/loading-panel";

import UserContext, { UserProvider } from "./components/user-context";

const App = () => {
	return (
		<UserProvider>
			<LoadingPanel />
			<BrowserRouter>
				<Route path="/" exact component={MainPage} />
				<Route path="/about" component={AboutPage} />
				<Route path="/login" component={LoginPage} />
				{/* 
				Adding a '?' to the end of the url param like this '/result/:searchTerm?' let us handle empty url parameter.
				This works because React Router 4 uses path-to-regexp to interpret its path property.
				*/}
				<Route path="/map/:poiId?" component={StreetMapPage} />
				<PrivateRoute path="/poi-submission-form" redirectPath={"/login"} component={PoiSubmissionForm} />

				{/* 
				Adding a '?' to the end of the url param like this '/result/:searchTerm?' let us handle empty url parameter.
				This works because React Router 4 uses path-to-regexp to interpret its path property.
				*/}
				<Route path="/store/:poiId?" component={StoreDetailsPage} />

				<Route path="/test" component={TestPage} />
			</BrowserRouter>
		</UserProvider>
	);
}

export default App;
