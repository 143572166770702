import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';

import { WEEK_DAY, getDayOfWeek, getOpenOrClosedString, debugPrintPoiBusinessHours, splitBusinessHoursFormattedString, poiDataToWeeklyBusinessHours } from './utils/hours-of-operation-util'

// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;


class HoursOfOperation extends Component {

    constructor(props) {
        super(props)

        this.toggleFullHoursOfOperation = this.toggleFullHoursOfOperation.bind(this);

        this.state = {
            weekHoursIsHidden: true,
        }
    }

    toggleFullHoursOfOperation() {
        this.setState({
            weekHoursIsHidden: !this.state.weekHoursIsHidden
        })
    }

    isToday = (currDayOfTheWeek) => {
        return new Date().getDay() === currDayOfTheWeek;
    }

    render() {
        let weeklyBusinessHours = poiDataToWeeklyBusinessHours(this.props.poiData);
        let date = getDayOfWeek();
        let todayBusinessHours = weeklyBusinessHours[date];

        // If today is the day of the week, make it bold
        const getTodayHoursOfOperation =
            (
                <button className="hours-of-operation-table-btn" onClick={this.toggleFullHoursOfOperation}>
                    <span className="font-weight-bold ">{getOpenOrClosedString(todayBusinessHours)}: </span>&nbsp;{todayBusinessHours.formattedString} &nbsp; <FontAwesomeIcon className="hours-of-operation-fa-svg" icon={faCaretDown} />
                </button>
            );

        const hoursOfOperationDay = (dayOfWeekIdx) => {
            let businessHours = weeklyBusinessHours[WEEK_DAY[dayOfWeekIdx]];
            let splitted = businessHours.toSpecialFormattedStringArray();

            if (splitted.length === 1) {
                return <tr align="left" className={this.isToday(dayOfWeekIdx) ? "font-weight-bold mb-0" : "text-secondary mb-0"}>
                    <td><span className="text-capitalize">{businessHours.date}</span>&nbsp;</td>
                    <td></td>
                    <td>{splitted[0]}</td>
                    <td></td>
                </tr>;
            } else if (splitted.length === 3) {
                return <tr align="left" className={this.isToday(dayOfWeekIdx) ? "font-weight-bold mb-0" : "text-secondary mb-0"}>
                    <td><span className="text-capitalize">{businessHours.date}</span>&nbsp;</td>
                    <td>{splitted[0]}&nbsp;</td>
                    <td>{splitted[1]}&nbsp;</td>
                    <td>{splitted[2]}</td>
                </tr>;
            } else {
                console.error("hoursOfOperationDay is not getting the right day operation hours.")
            }
        }

        return (
            <div className="text-center">
                {this.state.weekHoursIsHidden && getTodayHoursOfOperation}
                <div className="hours-of-operation-table" onClick={this.toggleFullHoursOfOperation} >
                    {!this.state.weekHoursIsHidden && <WeekHoursOfOperation hoursOfOperationDay={hoursOfOperationDay} />}
                </div>
            </div>
        )
    }
}

const WeekHoursOfOperation = (props) => {
    return (
        <div>
            <div align="center" className="text-secondary">
                <FontAwesomeIcon className="hours-of-operation-fa-svg" icon={faCaretUp} />
            </div>
            <table align="center">
                <tbody>
                    {props.hoursOfOperationDay(0)}
                    {props.hoursOfOperationDay(1)}
                    {props.hoursOfOperationDay(2)}
                    {props.hoursOfOperationDay(3)}
                    {props.hoursOfOperationDay(4)}
                    {props.hoursOfOperationDay(5)}
                    {props.hoursOfOperationDay(6)}
                </tbody>
            </table>
        </div>
    )
}

export default HoursOfOperation;
