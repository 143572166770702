import React, { useState } from 'react';
import { Redirect } from "react-router-dom";

import ReviewListItem from "./review-list-item";
import ReviewSubmissionPanel from "./review-submission";
import UserContext, { LOGIN_STATUS } from "./user-context";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';


// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;

export const ReviewState = {
    VIEW_REVIEWS: 0,
    WRITE_REVIEW: 1,
    EDIT_REVIEW: 2
}

// For examples of reviewsData, see getReviewsRestCall comments
// reloadPoiDetailsAfterReviewSubmission is basically StreetMapPage.reloadPoiDetailsAfterReviewSubmission
const ReviewSection = ({ reviewsData, reloadPoiDetailsAfterReviewSubmission }) => {

    // This governs the current state of this component
    const [reviewState, setReviewState] = useState(ReviewState.VIEW_REVIEWS);
    const [redirect, setRedirect] = useState(false);
    // This is the user review which contains review id that is used to edit user review
    const [userReview, setUserReview] = useState(undefined);

    let _allReviews = reviewsData ? reviewsData.all_reviews : [];

    let _userReviews = reviewsData ? reviewsData.user_reviews : [];

    let _hasAllReviews = _allReviews.length > 0;

    let _hasUserReviews = _userReviews.length > 0;

    const AllReviewItems = _allReviews.map((review, index) => {
        return <div className="review-border-bottom" key={index}><ReviewListItem review={review} /></div>
    });

    const UserReviewItems = _userReviews.map((review, index) => {
        return <div key={index}><ReviewListItem review={review} /></div>
    });

    const WriteReviewBtn = (
        <UserContext.Consumer>
            {(userContext) => (
                <div className="py-3">
                    <div className="text-center pt-4">
                        <h6>What do you think about this place?</h6>
                    </div>

                    <div className="card-body">
                        <button
                            type="button"
                            className="btn-custom btn btn-primary w-100"
                            onClick={() => onClickWriteReviewBtn(userContext)}
                        >
                            <FontAwesomeIcon className="rating-fa-svg pr-2" icon={faEdit} />Write a review
                            </button>
                    </div>
                </div>

            )}
        </UserContext.Consumer>
    );

    const CancelReviewBtn = (
        <div className="card-body">
            <button
                type="button"
                className="btn-custom btn btn-primary w-100"
                onClick={onClickCancelReviewBtn}
            >
                <FontAwesomeIcon className="rating-fa-svg pr-2" icon={faEdit} />Cancel
        </button>
        </div>
    );

    const EditReviewBtn = (
        <UserContext.Consumer>
            {(userContext) => (
                <div className="card-body">
                    <button
                        type="button"
                        className="btn-custom btn btn-primary w-100"
                        onClick={() => onClickEditReviewBtn(userContext)}
                    >
                        <FontAwesomeIcon className="rating-fa-svg pr-2" icon={faEdit} />Edit review
                        </button>
                </div>
            )}
        </UserContext.Consumer>
    );

    const YourReviewsList = (
        <div>
            <div className="card-body pb-0">
                <h5 className="font-weight-bold text-uppercase">Your Review</h5>
            </div>
            <div className="list-group">
                {UserReviewItems}
                {/* <div><ReviewListItem review={_allReviews[0]} /></div> */}
            </div>
        </div>
    );

    const _allReviewsList = (
        <div>
            <div className="review-border-top card-body pb-0">
                <h5 className="font-weight-bold text-uppercase">All Reviews</h5>
            </div>
            <div className="list-group">
                {AllReviewItems}
            </div>
        </div>
    )

    // const ReviewsList = (
    //     <div>
    //         {_hasUserReviews && YourReviewsList}

    //         {_hasAllReviews && _allReviewsList}
    //     </div>
    // );

    // const NoReviewsComponent = (
    //     <p className="mb-0 text-center">This place has no reviews yet!</p>
    // );

    function onClickWriteReviewBtn(userContext) {
        if (userContext.state.loginStatus === LOGIN_STATUS.FETCHED_USER_SUCCESS) {
            setReviewState(ReviewState.WRITE_REVIEW);
        } else {
            // If user is not logged in then redirect user to login page
            setRedirect(true);
        }
    }

    function onClickCancelReviewBtn(shouldReloadPoiDetailsAfterReviewSubmission) {
        // close the review panel
        setReviewState(ReviewState.VIEW_REVIEWS);
        if (shouldReloadPoiDetailsAfterReviewSubmission) {
            reloadPoiDetailsAfterReviewSubmission();
        }
    }

    function onClickEditReviewBtn(userContext) {
        if (!_hasUserReviews) {
            return;
        }

        if (userContext.state.loginStatus === LOGIN_STATUS.FETCHED_USER_SUCCESS) {
            setReviewState(ReviewState.EDIT_REVIEW);
            // just use the first user review since each use can only write one review
            let userReview = _userReviews[0];
            console.log("user review", userReview);
            setUserReview(userReview);
        } else {
            // If user is not logged in then redirect user to login page
            setRedirect(true);
        }
    }

    const getReviewSection = () => {
        if (reviewState === ReviewState.VIEW_REVIEWS) {
            return (
                <div>
                    {_hasUserReviews && YourReviewsList}

                    {_hasUserReviews ? EditReviewBtn : WriteReviewBtn}

                    {_hasAllReviews && _allReviewsList}
                </div>
            )
        } else if (reviewState === ReviewState.WRITE_REVIEW) {
            return (
                <div>
                    {CancelReviewBtn}
                    <ReviewSubmissionPanel parent_place={reviewsData.poi_id} reviewState={reviewState} onClickCancelReviewBtn={onClickCancelReviewBtn} />
                </div>
            )

        } else if (reviewState === ReviewState.EDIT_REVIEW && userReview) {
            return (
                <div>
                    {CancelReviewBtn}
                    <ReviewSubmissionPanel parent_place={reviewsData.poi_id} reviewState={reviewState} userReview={userReview} onClickCancelReviewBtn={onClickCancelReviewBtn} />
                </div>
            )
        } else {
            console.error("getReviewSection is in wrong review state: ", reviewState);
            return <div></div>
        }
    }

    function renderRedirect() {
        if (redirect) {
            return <Redirect to='/login' />
        }
    }

    return (
        <div>
            {renderRedirect()}
            {getReviewSection()}
        </div>
    );
}

export default ReviewSection;