import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Menu from "../components/menu"
import Footer from "../components/footer"

const Layout = (props) => {
    return (
        <div>
            <Menu />
            <div className="layout-container content-container">
                {props.children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout;
library.add(fab)
