/*=============================================================================

Useful resource:
https://docs.mapbox.com/help/tutorials/markers-js/#style-markers-with-simplestyle

=============================================================================*/

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import MapboxGl from "mapbox-gl";
import { Transition } from 'react-transition-group';
import "mapbox-gl/dist/mapbox-gl.css";

import Menu from "../components/menu";
import Footer from "../components/footer";
import BobaDetails from "../components/boba-details";
import { initializeReactGA } from '../components/utils/util';
import UserContext, { LOGIN_STATUS } from "../components/user-context";
import { getBobaListRestCall } from "../components/utils/rest-util";
import { getReviewsRestCall, getPlacePhotosRestCall, getPlaceRestCall } from "../components/utils/rest-util";
import { Coordinate, getDistanceInMiles } from '../components/utils/sort-util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { MOBILE_WIDTH, SHOW_SEARCH_HERE_BUTTON_MINIMUM_MILES } from "../components/constants";

// import MarkerImage from "../images/boba.png";
import MarkerImage from "../images/boba_marker-2.png";
import { SUCCESS } from '../components/utils/response-status';

const DEFAULT_MAP_ZOOM = 13;

const getInfo = (poiData, reviewsData, placePhotosData, reloadPoiDetailsAfterReviewSubmission, isMobile) => {
    if (poiData) {
        return (
            <div>
                <BobaDetails isMobile={isMobile} poiData={poiData} reviewsData={reviewsData} placePhotosData={placePhotosData} reloadPoiDetailsAfterReviewSubmission={reloadPoiDetailsAfterReviewSubmission} />
            </div>
        )
    } else {
        return (
            <div className="boba-details-footer-wrapper">
                <Footer></Footer>
            </div>
        )
    }
}

class StreetMapComponent extends Component {
    constructor(props) {
        super(props)
        initializeReactGA('/street-map');

        this.handleClickMarker = this.handleClickMarker.bind(this);
        this.mapMouseEnter = this.mapMouseEnter.bind(this);
        this.mapMouseLeave = this.mapMouseLeave.bind(this);
        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
        this.onClickMapSearchButton = this.onClickMapSearchButton.bind(this);
        this.createMapMarkerElements = this.createAllMapMarkerElements.bind(this);
        this.createMapMarkerElement = this.createMapMarkerElement.bind(this);
        this.handleFilterInput = this.handleFilterInput.bind(this);
        this.onMapMoveEnd = this.onMapMoveEnd.bind(this);
        this.handleChangeSearchInput = this.handleChangeSearchInput.bind(this);
        this.reloadPoiDetailsAfterReviewSubmission = this.reloadPoiDetailsAfterReviewSubmission.bind(this);

        this.state = {
            width: (typeof window !== 'undefined') ? window.innerWidth : 0,
            selectedGeoData: this.props.selectedGeoData, // selectedGeoData is poi data comes from the list of poi data seached near by, this is mianly used to render markers
            poiData: null, // poiData is fetched when a marker is clicked, this is used to popular details page
            reviewsData: null, // reviews are fetched when a marker is clicked, this is used to popular details page
            placePhotosData: null, // place photos are fetched when a marker is clicked, this is used to popular details page
            markerElements: [],
            idToPoiDictionary: null, // id to poi data dictionary, this is used for fast lookup 
            selectedMarkerElement: null,
            searchHereVisible: false,
            searchValue: "", // Filter text used for searching boba shops on map
            redirect: false // Used to handle url redirect when user clicks on a boba place
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);

        // Always reset window scroll
        this.resetWindowScroll()

        // If there is no selected geo data but there is a route param poi id, then this boba 
        // place is accessed through a link directly and not linked through a poi list item
        // which means we need to fetch the place details data and display it on the map
        // Note that routeParamPoiId is the poiId obtained from route path "/map/:poiId"
        if (!this.state.selectedGeoData && !this.props.userContext.state.poiList && this.props.routeParamPoiId) {

            this.handleSharedLinkFetchingOnePoi(this.props.routeParamPoiId);

        } else if (this.props.userContext.state.poiList && this.state.selectedGeoData) {
            // If there are both poi list and selected geo data then we know the user is redirected from boba list page by clicking on a boba list item
            let poiList = this.props.userContext.state.poiList;
            const { markerElements, selectedMarkerElement, idToPoiDictionary } = this.createAllMapMarkerElements(poiList, this.state.selectedGeoData);
            this.setState({ markerElements, selectedMarkerElement, idToPoiDictionary });
            this.initializeMap(this.state.selectedGeoData, markerElements, selectedMarkerElement);
        } else {
            // Otherwise we need to search for boba near by
            this.props.userContext.setShowLoadingPanel(true);
            getBobaListRestCall(this.props.userContext.state.searchCoordinate)
                .then((data) => {
                    let poiList = data.pois;
                    this.props.userContext.updatePoiList(poiList);
                    this.removeMapboxMarkers();
                    const { markerElements, selectedMarkerElement, idToPoiDictionary } = this.createAllMapMarkerElements(poiList, this.state.selectedGeoData);
                    this.setState({ markerElements, selectedMarkerElement, idToPoiDictionary });
                    this.initializeMap(undefined, markerElements, selectedMarkerElement);
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.props.userContext.setShowLoadingPanel(false);
                });
        }
    }

    // This boba place is accessed through a link directly and not linked through a poi list item
    // which means we need to fetch the place details data and display it on the map
    handleSharedLinkFetchingOnePoi(poiId) {
        this.props.userContext.setShowLoadingPanel(true);
        getPlaceRestCall(poiId)
            .then((data) => {
                console.log("Accessed through a place url directlly, need to fetch poi data", data.poi);
                let poi = data.poi;
                let poiList = [data.poi];
                const { markerElements, selectedMarkerElement, idToPoiDictionary } = this.createAllMapMarkerElements(poiList, poi);
                this.setState({ markerElements, selectedMarkerElement, idToPoiDictionary });
                this.initializeMap(poi, markerElements, selectedMarkerElement);

                // Need to set selected geo data to the poi specified in the url
                this.setState({ selectedGeoData: poi });

                // Need to show search button on start if player directly access this poi through an url
                this.setState({ searchHereVisible: true });
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                this.props.userContext.setShowLoadingPanel(false);
            });
    }

    refreshPage() {
        window.location.reload(false);
    }

    // It is invoked immediately after updating occurs. This method is not called for the initial render.
    componentDidUpdate(prevProps) {

        // Check if route param has changed, route param changes when user clicks on a new boba marker or clicks on the back button in browser
        let currRouteParamPoiId = this.props.routeParamPoiId;
        if (prevProps.routeParamPoiId !== this.props.routeParamPoiId) {

            if (!this.state.selectedGeoData) {
                // Reload that single element if its not in the list
                this.refreshPage();
            } else if (currRouteParamPoiId !== this.state.selectedGeoData.uuid) {
                // If current route param poi id is not the same as the selected geo data, that means the back button is clicked
                // console.log("===== componentDidUpdate back button is clicked, selectedGeoData.uuid", this.state.selectedGeoData.uuid, "routeParamPoiId", currRouteParamPoiId);
                let poiList = this.props.userContext.state.poiList;
                let markerElements = this.state.markerElements;
                if (poiList && markerElements) {
                    let selectedGeoData = poiList.find(poi => poi.uuid === currRouteParamPoiId);
                    let selectedMarkerElement = markerElements.find(e => e.id === currRouteParamPoiId);
                    // If the marker element is hidden it's probably fillered out, so we just want to refresh the page to go to that element
                    if (selectedGeoData && selectedMarkerElement && selectedMarkerElement.style.visibility !== "hidden") {
                        this.getPoiDetails(selectedGeoData, selectedMarkerElement);
                    } else {
                        // Reload that single element if its not in the list
                        this.refreshPage();
                    }
                } else {
                    // Reload that single element if its not in the list
                    this.refreshPage();
                }
            }
        }
    }


    handleWindowSizeChange() {
        this.setState({ width: window.innerWidth });
    }

    handleClickMarker(data, markerElement) {
        console.log("handleClickMarker: ", data);

        // When user clicks on the selected marker again, we want to prevent fetching the same
        // data, but we do want to refocus on the marker and reset window scroll
        if (this.state.selectedGeoData === data) {
            this.resetWindowScroll();
            this.map.easeTo({ 
                center: [data.geo_geometry_longitude, data.geo_geometry_latitude],
                zoom: DEFAULT_MAP_ZOOM
            });
            return;
        }

        this.getPoiDetails(data, markerElement);

        this.setState({ redirect: true });

    }

    resetWindowScroll() {
        // Reset the scroll
        if (window) {
            window.scrollTo(0, 0);
        }
    }

    reloadPoiDetailsAfterReviewSubmission() {
        this.getPoiDetails(this.state.selectedGeoData, this.state.selectedMarkerElement);
    }

    getPoiDetails(data, markerElement) {
        this.resetWindowScroll();

        // console.log("marker selected " + data.uuid);
        if (!this.map) {
            console.log("map is not ready yet...")
            return;
        }

        this.map.easeTo({ 
            center: [data.geo_geometry_longitude, data.geo_geometry_latitude],
            zoom: DEFAULT_MAP_ZOOM
        });

        // Unhighlight previous marker
        let prevMarkerElement = this.state.selectedMarkerElement;
        if (prevMarkerElement) {
            this.setMarkerElementStyle(prevMarkerElement, false);
        }

        // Highlight current marker
        this.setMarkerElementStyle(markerElement, true);

        // Update selected marker state
        this.setState({
            selectedGeoData: data,
            selectedMarkerElement: markerElement,
            selectedPoiUuid: data.uuid
        });

        // Replace the footer element in side info div with boba place information

        getPlaceRestCall(data.uuid)
            .then(data => {
                if (data.status === SUCCESS) {
                    console.log(`fetched poi data for poi`, data);
                    this.setState({ poiData: data.poi });
                }
            })
            .catch(err => {
                console.error(err)
            });

        let authResponse = this.props.userContext.state.authResponse;
        if (authResponse) {
            getReviewsRestCall(data.uuid, authResponse.userID, authResponse.accessToken)
                .then(reviewsData => {
                    if (reviewsData.status === SUCCESS) {
                        console.log(`fetched reviews data for poi`, reviewsData);
                        this.setState({ reviewsData });
                    } else {
                        console.error("getReviewsRestCall data status is not success, it is ", reviewsData.status);
                    }
                })
                .catch(err => {
                    console.error(err)
                });
        } else {
            getReviewsRestCall(data.uuid)
                .then(reviewsData => {
                    if (reviewsData.status === SUCCESS) {
                        console.log(`fetched reviews data for poi`, reviewsData);
                        this.setState({ reviewsData });
                    } else {
                        console.error("getReviewsRestCall data status is not success, it is ", reviewsData.status);
                    }
                })
                .catch(err => {
                    console.error(err)
                });
        }

        getPlacePhotosRestCall(data.uuid)
            .then(data => {
                if (data.status === SUCCESS) {
                    console.log(`fetched place photo data for poi`);
                    this.setState({ placePhotosData: data });
                }
            })
            .catch(err => {
                console.error(err)
            });
    }

    mapMouseEnter() {
        // Change the cursor to a pointer when the it enters a marker
        if (this.map) {
            this.map.getCanvas().style.cursor = 'pointer';
        }
    }

    mapMouseLeave() {
        // Change the cursor back when it leaves a marker
        if (this.map) {
            this.map.getCanvas().style.cursor = '';
        }
    }

    createAllMapMarkerElements(poiList, selectedGeoData) {
        let markerElements = [];
        let self = this;
        let selectedMarkerElement = null;
        let idToPoiDictionary = {};
        poiList.forEach((data) => {
            // create a HTML element for each feature
            // All poi data must have id's
            let isSelected = selectedGeoData ? data.uuid === selectedGeoData.uuid : false;
            let markerElement = self.createMapMarkerElement(data, isSelected);
            markerElements.push(markerElement);
            idToPoiDictionary[data.uuid] = data;
            if (isSelected) {
                selectedMarkerElement = markerElement;
            }
        });
        return {
            markerElements: markerElements,
            selectedMarkerElement: selectedMarkerElement,
            idToPoiDictionary: idToPoiDictionary
        }
    }

    onMapMoveEnd() {

        // Show the "search here" button.
        if (this.map) {
            let center = this.map.getCenter();
            let distance_miles = getDistanceInMiles(
                [
                    this.props.userContext.state.searchCoordinate.lat,
                    this.props.userContext.state.searchCoordinate.lng
                ],
                [
                    center.lat,
                    center.lng
                ]
            );
            if (distance_miles > SHOW_SEARCH_HERE_BUTTON_MINIMUM_MILES) {
                this.setState({ searchHereVisible: true });
            }
        }
    }

    initializeMap(selectedGeoData, markerElements, selectedMarkerElement) {
        // If there is no selected geo data, user user context search coordinate instead
        let mapCenter = selectedGeoData ?
            [selectedGeoData.geo_geometry_longitude, selectedGeoData.geo_geometry_latitude] :
            [this.props.userContext.state.searchCoordinate.lng, this.props.userContext.state.searchCoordinate.lat]

        // Listen to map changes
        this.map = new MapboxGl.Map({
            container: 'map',
            style: 'https://api.maptiler.com/maps/6b324e36-7a4e-4270-bb36-f546486b6ea7/style.json?key=2eJcFrA3D9UMI671P5cd',
            center: mapCenter,
            zoom: DEFAULT_MAP_ZOOM,
            minZoom: 9,
            maxZoom: 20,
            // Bounds restrict map view to only SF bay area
            // maxBounds: [
            //     [-122.9194, 37.2749], // Southwest coordinates
            //     [-121.9194, 38.2749]  // Northeast coordinates
            // ]
        });

        // this.map.addControl(new MapboxGl.NavigationControl());

        // Add geolocate control to the map.
        this.map.addControl(new MapboxGl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        }),
            'bottom-left'
        );

        // Handle when the map moves to notify the user that they should search here.

        // Reinitialize the mapbox marker list
        let mapElement = document.getElementById("map");
        this.currentMapboxMarkers = markerElements.map(
            markerElement => {
                let lat = markerElement.dataset.lat;
                let lng = markerElement.dataset.lng;
                return this.addMarkerToMap(markerElement, lat, lng, this.map, mapElement);
            });

        this.map.on("moveend", this.onMapMoveEnd);

        if (selectedGeoData) {
            // If there is a selected poi, set the map to focus on the selected marker on start
            this.getPoiDetails(selectedGeoData, selectedMarkerElement);
        }
    }

    handleChangeSearchInput(e) {
        this.setState({ searchValue: e.target.value });
    }

    handleFilterInput(e) {
        // it's visibility to 'visible' or else hide it.
        let self = this; // We need to do this since "this" goes out of scope.
        if (self.currentMapboxMarkers && self.map) {
            var value = e.target.value.trim().toLowerCase();
            self.currentMapboxMarkers.forEach(function (mapboxMarker) {
                let element = mapboxMarker.getElement();
                let poi = self.state.idToPoiDictionary[element.id];
                if (poi) {
                    let searchParams = (poi.general_title + " " + poi.general_address).toLowerCase();
                    element.style.visibility = searchParams.indexOf(value) > -1 ? 'visible' : 'hidden';
                } else {
                    element.style.visibility = 'hidden';
                }
            });
        }
    }

    createMapMarkerElement(data, isActive) {
        let element = document.createElement("div");
        element.className = "marker";
        element.id = data.uuid; // Just use the uuid, this makes it easier for search when clicking on the back button
        element.setAttribute("data-lat", data.geo_geometry_latitude);
        element.setAttribute("data-lng", data.geo_geometry_longitude);
        element.addEventListener("click", () => { this.handleClickMarker(data, element); });
        this.setMarkerElementStyle(element, isActive);
        return element;
    }

    setMarkerElementStyle(element, isActive) {
        element.style.backgroundImage = "url(" + MarkerImage + ")";
        element.style.backgroundSize = "cover";
        // Make sure the selected marker is bigger than the other markers
        element.style.width = isActive ? "60px" : "24px";
        element.style.height = isActive ? "80px" : "32px";
        element.style.borderRadius = "50%";
        element.style.cursor = "pointer";
        // Make sure the selected marker is on top of the other markers
        element.style.zIndex = isActive ? "2" : "1";
    }

    addMarkerToMap(markerElement, lat, lng, map, mapElement) {
        mapElement.appendChild(markerElement);
        return new MapboxGl.Marker(markerElement)
            .setLngLat([lng, lat])
            .addTo(map);
    }

    removeMapboxMarkers() {
        // remove mapbox markers on the map
        if (this.currentMapboxMarkers) {
            this.currentMapboxMarkers.forEach(e => e.remove());
        }
    }

    onClickMapSearchButton() {
        // Get center from mapboxGL
        let { lng, lat } = this.map.getCenter();
        let coordinate = new Coordinate(lat, lng);
        this.props.userContext.updateSearchCoordinate(coordinate);
        getBobaListRestCall(coordinate)
            .then((data) => {
                if (!this.state.searchHereVisible) {
                    console.log("Cannot search this place twice.")
                    return;
                }
                let poiList = data.pois;
                this.props.userContext.updatePoiList(poiList);
                this.props.userContext.updateSearchCoordinate(coordinate);
                this.removeMapboxMarkers();
                this.setState({ searchHereVisible: false });
                const { markerElements, selectedMarkerElement, idToPoiDictionary } = this.createAllMapMarkerElements(poiList, this.state.selectedGeoData);
                this.setState({ markerElements, selectedMarkerElement, idToPoiDictionary });
                let mapElement = document.getElementById("map");
                this.currentMapboxMarkers = markerElements.map(markerElement => {
                    let lat = markerElement.dataset.lat;
                    let lng = markerElement.dataset.lng;
                    return this.addMarkerToMap(markerElement, lat, lng, this.map, mapElement);
                })

                // Upon finish searching, we need to clear the filter input
                this.setState({ searchValue: "" });
            })
            .catch(err => {
                console.error(err)
            });
    }

    searchHereTooltipPosition({ left, top }, currentEvent, currentTarget, node) {
        var rect = currentTarget.getBoundingClientRect();
        left = rect.left - (node.clientWidth / 2) + (currentTarget.clientWidth / 2);
        top = rect.bottom;
        return { top, left }
    }

    // Used to handle url redirect when user clicks on a boba place
    handleUrlRedirect() {
        return this.state.redirect && <Redirect push to={{
            pathname: `/map/${this.state.selectedGeoData.uuid}`,
            poi: this.state.selectedGeoData
        }} />;
    }

    filterInput() {
        return (
            <input
                type="text"
                className="map-search-bar form-control"
                placeholder="Search"
                value={this.state.searchValue}
                onChange={this.handleChangeSearchInput}
                onKeyUp={this.handleFilterInput}
            />
        );
    }

    searchButton() {
        return this.state.searchHereVisible &&
            <button
                type="button"
                className="map-search-btn btn"
                onClick={this.onClickMapSearchButton}
                data-tip
                data-for='search-here-tooltip'
            >
                <span><FontAwesomeIcon className="map-fa-svg mr-2" icon={faSearchLocation} /></span>Search Here
            </button>;
    }

    // searchButtonWithAnimationTransition() {
    //     const seachHereDefaultStyle = {
    //         transition: `opacity 500ms ease-in-out`
    //     }

    //     const searchHereTransitionStyles = {
    //         entering: { opacity: 1, visibility: "visible", display: "block" },
    //         entered: { opacity: 1 },
    //         exiting: { opacity: 0 },
    //         exited: { opacity: 0, visibility: "hidden", display: "none" },
    //     };

    //     return <Transition in={this.state.searchHereVisible} timeout={{
    //         appear: 400,
    //         enter: 0,
    //         exit: 0,
    //     }}>
    //         {state => (
    //             <button
    //                 type="button"
    //                 className="map-search-btn btn btn-outline-primary mt-2"
    //                 onClick={this.onClickMapSearchButton}
    //                 data-tip
    //                 data-for='search-here-tooltip'
    //                 style={{
    //                     ...seachHereDefaultStyle,
    //                     ...searchHereTransitionStyles[state]
    //                 }}
    //             >
    //                 <span><FontAwesomeIcon className="map-fa-svg mr-2" icon={faSearchLocation} /></span>
    //                 Search Here
    //             </button>
    //         )}
    //     </Transition>;
    // }

    render() {
        const width = this.state.width;
        const isMobile = width <= MOBILE_WIDTH;

        return (
            <div>

                {this.handleUrlRedirect()}

                <Menu removeBackground={true} />
                <div className={isMobile ? "map-container-mobile" : "map-container"}>


                    {this.filterInput()}
                    {this.searchButton()}

                    <div id='map'></div>
                </div>
                <div className={isMobile ? "map-info-container-mobile" : "map-info-container"}>
                    {getInfo(this.state.poiData, this.state.reviewsData, this.state.placePhotosData, this.reloadPoiDetailsAfterReviewSubmission, isMobile)}
                </div>
            </div>
        );
    }
}


/**
 * Go to this page:             
 * <Link to={{pathname: `/map/${poi.uuid}`}>
 */
// selectedGeoData is passed in from BobaListItem component through Link's location descriptor, if it is undefined that means
// user opened map page without clicking on the list item
const StreetMapPage = (props) => {
    return (
        <div>
            <UserContext.Consumer>
                {(userContext) => {
                    if (userContext.state.loginStatus === LOGIN_STATUS.FAILURE ||
                        userContext.state.loginStatus === LOGIN_STATUS.FETCHED_USER_SUCCESS) {
                        return <StreetMapComponent userContext={userContext} selectedGeoData={props.location.poi} routeParamPoiId={props.match.params.poiId} />;
                    }

                    // This happens when login status is NONE or SUCCESS, if NONE then we show a blank page while waiting for the user to get 
                    // login status, if SUCCESS, we show a blank page while waiting to get user data then login status would become FETCHED_USER_SUCCESS
                    return <div></div>
                }}
            </UserContext.Consumer>
        </div>
    )
}

export default StreetMapPage;
