import React from 'react';

import Layout from "../components/layout";
import UserContext from "../components/user-context";
import FacebookLogin from "../components/facebook-login";

import DesireBobaImg from "../images/boba_avatar/WB_Desire.png";

const LoginPage = () => {

    return (
        <Layout>
            <div className="login-content-container text-center p-4">

                <div className="pb-4">
                    <h6>Login to help contribute!</h6>
                    <UserContext.Consumer>
                        {(userContext) => (
                            <FacebookLogin userContext={userContext} />
                        )}
                    </UserContext.Consumer>
                </div>

                <div className="pt-4">
                    <img src={DesireBobaImg} className="login-img img-fluid" alt=""></img>
                </div>
            </div>
        </Layout>
    )
}

export default LoginPage;
