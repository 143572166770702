import React, { Component } from 'react';
import axios from 'axios';

import Layout from "./layout";
import UserContext from "./user-context";
import { isBlank, compressImage } from "./utils/util";
import { postWriteReview, patchEditReview } from "./utils/rest-util";
import { REVIEW_BODY_MAX_CHARS } from "./constants";
import { SUCCESS } from '../components/utils/response-status';
import { ReviewState } from "../components/review-section";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core';
// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;

/*
{"title":"Review Title", "body":"Stuff here", "main_rating":5,"parent_place":"{{SAMPLE_POI_UUID}}"}
*/


class ReviewSubmissionComponent extends Component {
    constructor(props) {
        super(props)

        let isEditingReview = this.props.reviewState === ReviewState.EDIT_REVIEW && this.props.userReview;

        this.state = {
            /*====== Used for submit review POST end point ======*/
            title: isEditingReview ? this.props.userReview.title : "",
            main_rating: isEditingReview ? this.props.userReview.main_rating : "",
            body: isEditingReview ? this.props.userReview.body : "",

            // We only support uploading three images at the moment
            first_image_file: null,
            second_image_file: null,
            third_image_file: null,

            // Since for now editing doesn't support modifiying images, keep a boolean here to disable image uploading UI
            disableImageUploading: isEditingReview ? true : false,
        };

        this.handleChangeReviewTitle = this.handleChangeReviewTitle.bind(this);
        this.handleChangeReviewRating = this.handleChangeReviewRating.bind(this);
        this.handleChangeReviewBody = this.handleChangeReviewBody.bind(this);

        this.handleSelectFirstImageFile = this.handleSelectFirstImageFile.bind(this);
        this.handleSelectSecondImageFile = this.handleSelectSecondImageFile.bind(this);
        this.handleSelectThirdImageFile = this.handleSelectThirdImageFile.bind(this);

    }

    handleChangeReviewTitle(event) {
        this.setState({ title: event.target.value });
    }

    handleChangeReviewRating(event) {
        this.setState({ main_rating: event.target.value });
    }

    handleChangeReviewBody(event) {
        this.setState({ body: event.target.value });
    }

    handleSelectFirstImageFile(event) {
        if (event.target.files && event.target.files[0]) {
            this.setState({ first_image_file: event.target.files[0] })
        } else {
            // Handle set image canceled
            this.setState({ first_image_file: null })
        }
    }

    handleSelectSecondImageFile(event) {
        if (event.target.files && event.target.files[0]) {
            this.setState({ second_image_file: event.target.files[0] })
        } else {
            // Handle set image canceled
            this.setState({ second_image_file: null })
        }
    }

    handleSelectThirdImageFile(event) {
        if (event.target.files && event.target.files[0]) {
            this.setState({ third_image_file: event.target.files[0] })
        } else {
            // Handle set image canceled
            this.setState({ third_image_file: null })
        }
    }

    reviewSubmissionFailedMessage(error) {
        return `Review submission failed, please try again. Reason: ${error}`;
    }

    handleSubmit = userContext => event => {
        event.preventDefault();

        if (!userContext.state.authResponse) {
            return;
        }

        if (!this._fieldsAreValid()) {
            console.error("fields aren't valid");
            this.setState({ error_message_displayed: this.reviewSubmissionFailedMessage("some of your your form fields are not valid") });
            return;
        }

        this.props.userContext.setShowLoadingPanel(true);

        this._tryCompressImagesThenUploadData(userContext);
    }

    _tryCompressImagesThenUploadData(userContext) {
        let imageFiles = [];
        if (this.state.first_image_file) {
            imageFiles.push(this.state.first_image_file);
        }
        if (this.state.second_image_file) {
            imageFiles.push(this.state.second_image_file);
        }
        if (this.state.third_image_file) {
            imageFiles.push(this.state.third_image_file);
        }

        if (imageFiles.length === 0) {
            if (this.props.reviewState === ReviewState.WRITE_REVIEW) {
                this.sendWriteReviewPostRequest(userContext, []);
            } else if (this.props.reviewState === ReviewState.EDIT_REVIEW && this.props.userReview) {
                let userReviewId = this.props.userReview.uuid;
                if (userReviewId) {
                    this.sendEditReviewPatchRequest(userContext, userReviewId, []);
                } else {
                    console.error("User review has no uuid", this.props.userReview)
                }
            }
        } else {
            let self = this;
            Promise.all(imageFiles.map(i => compressImage(i)))
                .then((compressedFiles) => {
                    if (self.props.reviewState === ReviewState.WRITE_REVIEW) {
                        self.sendWriteReviewPostRequest(userContext, compressedFiles);
                    } else if (self.props.reviewState === ReviewState.EDIT_REVIEW && self.props.userReview) {
                        let userReviewId = self.props.userReview.uuid;
                        if (userReviewId) {
                            self.sendEditReviewPatchRequest(userContext, userReviewId, compressedFiles);
                        } else {
                            console.error("User review has no uuid", self.props.userReview)
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                    self.props.userContext.setShowLoadingPanel(false);
                });
        }
    }

    sendWriteReviewPostRequest(userContext, imageFilesToUpload) {
        const formData = this._constructFormData(imageFilesToUpload);
        const accessToken = userContext.state.authResponse.accessToken;
        const userID = userContext.state.authResponse.userID;

        postWriteReview(formData, userID, accessToken)
            .then(res => {
                if (res.status === SUCCESS) {
                    this.props.onClickCancelReviewBtn(true);
                } else {
                    this.setState({ error_message_displayed: this.reviewSubmissionFailedMessage(res.status) });
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                this.props.userContext.setShowLoadingPanel(false);
            })
    }

    sendEditReviewPatchRequest(userContext, reviewId, imageFilesToUpload) {
        const accessToken = userContext.state.authResponse.accessToken;
        const userID = userContext.state.authResponse.userID;

        // edit review end point uses json data
        const data = JSON.stringify({
            title: this.state.title,
            body: this.state.body,
            main_rating: this.state.main_rating
        });

        patchEditReview(data, reviewId, userID, accessToken)
            .then(res => {
                if (res.status === SUCCESS) {
                    this.props.onClickCancelReviewBtn(true);
                } else {
                    this.setState({ error_message_displayed: this.reviewSubmissionFailedMessage(res.status) });
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                this.props.userContext.setShowLoadingPanel(false);
            })
    }

    _constructFormData(imageFiles) {
        const formData = new FormData();

        imageFiles.forEach(i => {
            formData.append("place_photos", i);
        });

        formData.append("review_data", JSON.stringify({
            parent_place: this.props.parent_place,
            title: this.state.title,
            body: this.state.body,
            main_rating: this.state.main_rating
        }));

        return formData;
    }

    _fieldsAreValid() {
        return !isBlank(this.state.title)
            && !isBlank(this.state.body)
            && !isBlank(this.props.parent_place)
            && 1 <= this.state.main_rating && this.state.main_rating <= 5;
    }

    _getFileNameForInputLabel(file) {
        return (file && file.name) ? file.name : "Choose file"
    }

    render() {

        let PhotoUploadingComponent = <div>
            <h5>Upload Photos</h5>
            <p className="form-text text-muted">Only three gallery photos are allowed currently</p>
            <div className="d-flex justify-content-center mb-3">
                <div className="custom-file">
                    <input
                        type="file"
                        accept="image/*"
                        className="custom-file-input"
                        id="firstImageFile"
                        onChange={this.handleSelectFirstImageFile} />
                    <label className="custom-file-label" htmlFor="firstImageFile">{this._getFileNameForInputLabel(this.state.first_image_file)}</label>
                </div>
            </div>
            <div className="d-flex justify-content-center mb-3">
                <div className="custom-file">
                    <input
                        type="file"
                        accept="image/*"
                        className="custom-file-input"
                        id="secondImageFile"
                        onChange={this.handleSelectSecondImageFile} />
                    <label className="custom-file-label" htmlFor="secondImageFile">{this._getFileNameForInputLabel(this.state.second_image_file)}</label>
                </div>
            </div>
            <div className="d-flex justify-content-center mb-3">
                <div className="custom-file">
                    <input
                        type="file"
                        accept="image/*"
                        className="custom-file-input"
                        id="thirdImageFile"
                        onChange={this.handleSelectThirdImageFile} />
                    <label className="custom-file-label" htmlFor="thirdImageFile">{this._getFileNameForInputLabel(this.state.third_image_file)}</label>
                </div>
            </div>
        </div>;

        return (
            <div id="review-submission-panel">

                {/* <button
                    type="button"
                    className="btn ml-2 mt-2"
                    onClick={() => this.props.onClickCancelReviewBtn()}
                >
                    <FontAwesomeIcon className="sorting-panel-fa-svg" icon={faTimes} />
                </button> */}

                <form onSubmit={this.handleSubmit(this.props.userContext)} className="p-4">

                    <div className="form-group">
                        <label htmlFor="address">Review title</label>
                        <input
                            type="text" className="form-control" placeholder=""
                            value={this.state.title}
                            onChange={this.handleChangeReviewTitle}
                            required />
                        <small className="form-text text-muted">This field is required</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Rating</label>
                        <select
                            className="form-control"
                            value={this.state.main_rating}
                            onChange={this.handleChangeReviewRating}
                            required >
                            <option value="" hidden></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="address">Review body</label>
                        <textarea
                            maxLength={REVIEW_BODY_MAX_CHARS}
                            type="text" className="form-control" placeholder=""
                            value={this.state.body}
                            onChange={this.handleChangeReviewBody}
                            required />
                    </div>

                    {!this.state.disableImageUploading && PhotoUploadingComponent}

                    <div className="d-flex justify-content-center mb-3">
                        <button
                            type="submit"
                            className="btn-custom btn btn-primary w-100"
                        >
                            Submit</button>
                    </div>

                    <div className="error-message pt-3 text-center">
                        <h5>{this.state.error_message_displayed}</h5>
                    </div>

                </form>
            </div>
        )
    }
}

/*
example props:

props = {
    parent_place: boba shop uuid,
    reviewState: ReviewState enum,
    userReview: review made by the user, userReview.uuid is the id of the review used for editing the review, see getReviewsRestCall
    onClickCancelReviewBtn: this is a function that binds to ReviewSection.onClickCancelReviewBtn which toggles the sorting panel,
}

 */
const ReviewSubmissionPanel = ({ parent_place, reviewState, userReview, onClickCancelReviewBtn }) => {
    return (
        <div>
            <UserContext.Consumer>
                {(userContext) => (
                    <ReviewSubmissionComponent
                        userContext={userContext}
                        parent_place={parent_place}
                        reviewState={reviewState}
                        userReview={userReview}
                        onClickCancelReviewBtn={onClickCancelReviewBtn}
                    />
                )}
            </UserContext.Consumer>
        </div>
    )
}

export default ReviewSubmissionPanel;