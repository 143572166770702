import React, { useState } from 'react';

import HoursOfOperationFrom from "./hours-of-operation";
import DeliveryLinks from "./delivery-links";
import ReviewSection from "./review-section";
import PlacePhotosSection from "./place-photos-section";
import MenuInfo from "./menu/menu-info";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faYelp, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faCrown, faPhone, faMapMarkerAlt, faStar, faMapMarkedAlt, faHeart } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';

import { getGoogleMapSearchUrl, getRatingReviewString, getTelephoneLink, isNullOrUndefined, shortenAddress } from './utils/util';

import MapIcon from "../images/flaticon/pin.png";
import PhoneIcon from "../images/flaticon/phone.png";
import FavoriteIcon from "../images/flaticon/star_black_and_white.png";


// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;


const DetailTabEnum = {
    INFO: 0,
    GALLERY: 1,
    REVIEWS: 2
}

const BobaDetails = ({ isMobile, poiData, reviewsData, placePhotosData, reloadPoiDetailsAfterReviewSubmission }) => {

    const [activeDetailTab, setActiveDetailTab] = useState(DetailTabEnum.INFO);

    const phoneNumberComponent = (
        <p className="map-card-link card-text">
            <span className="mr-3"><FontAwesomeIcon className="details-fa-svg" icon={faPhone} /></span>{poiData.general_phone_number}
        </p>
    );

    const callButtonComponent = (
        <button type="button" className="boba-details-btn btn btn-outline-primary mr-2">
            <a
                href={getTelephoneLink(poiData.general_phone_number)}
                rel="noopener noreferrer"
                target="_blank"
            >
                Call
            </a>
        </button>
    );

    const recommendedFlagComponent = (
        <span className="details-recommended-fa-container ml-3"><FontAwesomeIcon className="details-recommended-fa-svg" icon={faCrown} /></span>
    );

    // Scroll boba details to the top of the window
    const toTopOfWindow = () => {
        if (window) {
            window.scrollTo({
                top: window.screen.height / 2 + 56,
                behavior: 'smooth'
            });
        }
    }

    const detailsPageToTopButton = () => {
        return <button id="details-page-to-top-button" type="button" className="btn p-0 m-0 border-0" onClick={toTopOfWindow}>
            {/* <span className=""><FontAwesomeIcon className="details-up-btn-fa-svg" icon={faCaretUp} />&mdash;</span> */}
            <span className="detail-up-btn"></span>
        </button>;
    };

    const getDetailTabLiClassName = (tabEnum) => {
        return activeDetailTab === tabEnum ? "detail-tab-li-selected" : "detail-tab-li";
    }

    const handleClickDetailTab = tabEnum => {
        setActiveDetailTab(tabEnum);
    }

    const DetailCallBtn = () => {
        if (!isNullOrUndefined(poiData.general_phone_number)) {
            return (
                <div>
                    <a
                        href={getTelephoneLink(poiData.general_phone_number)}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <FontAwesomeIcon className="detail-btn-container-fa-svg" icon={faPhone} />
                        {/* <img className="detail-btn-container-icon-img" src={PhoneIcon} alt=""></img> */}
                        <div className="detail-btn-text">Call</div>
                    </a>
                </div>
            );
        } else {
            return (
                <div>
                    <FontAwesomeIcon className="detail-btn-inactive detail-btn-container-fa-svg" icon={faPhone} />
                    {/* <img className="detail-btn-container-icon-img" src={PhoneIcon} alt=""></img> */}
                    <div className="detail-btn-inactive detail-btn-text">Call</div>
                </div>
            )
        }
    }

    const InfoTabContent = (
        <div>
            {/* <div className="card-body">

                <div className="">
                    <h6>{poiData.general_description}</h6>
                </div>
            </div>

            <div className="card-body pt-0">
                <HoursOfOperationFrom poiData={poiData}></HoursOfOperationFrom>
            </div> */}

            <DeliveryLinks
                delivery_caviar={poiData.delivery_caviar}
                delivery_doordash={poiData.delivery_doordash}
                delivery_grubhub={poiData.delivery_grubhub}
                delivery_ubereats={poiData.delivery_ubereats}
                delivery_postmates={poiData.delivery_postmates} />

            <MenuInfo />
        </div>
    )

    const GalleryTabContent = <PlacePhotosSection placePhotosData={placePhotosData} reloadPoiDetailsAfterReviewSubmission={reloadPoiDetailsAfterReviewSubmission} />;

    const ReviewsTabContent = <ReviewSection reviewsData={reviewsData} reloadPoiDetailsAfterReviewSubmission={reloadPoiDetailsAfterReviewSubmission} />;


    const TabContent = () => {
        switch (activeDetailTab) {
            case DetailTabEnum.INFO:
                return InfoTabContent;
            case DetailTabEnum.GALLERY:
                return GalleryTabContent;
            case DetailTabEnum.REVIEWS:
                return ReviewsTabContent;
            default:
                throw new Error('Unknown DetailTabEnum: ' + activeDetailTab);
        }
    }

    const TabContentWrapper = (
        <div className="boba-detail-info-section">
            {TabContent()}

            <ul className={isMobile ? "detail-btn-container-mobile detail-btn-container" : "detail-btn-container-desktop detail-btn-container"} >
                <li className="detail-btn-li">
                    <a
                        href={getGoogleMapSearchUrl(poiData.general_title, poiData.general_address)}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <FontAwesomeIcon className="detail-btn-container-fa-svg" icon={faMapMarkedAlt} />
                        {/* <img className="detail-btn-container-icon-img" src={MapIcon} alt=""></img> */}
                        <div className="detail-btn-text">Google Maps</div>
                    </a>
                </li>
                <li className="detail-btn-li">
                    {DetailCallBtn()}
                </li>
                <li className="detail-btn-li">
                    <FontAwesomeIcon className="detail-btn-container-fa-svg" icon={faHeart} />
                    {/* <img className="detail-btn-container-icon-img" src={FavoriteIcon} alt=""></img> */}
                    <div className="detail-btn-text">Favorite</div>
                </li>
            </ul>
        </div>
    );

    return (
        <div>
            {/* <div className="text-center">
                {detailsPageToTopButton()}
            </div> */}

            <div className="card-body">
                <h4 className="card-title">
                    {poiData.general_title}
                    {poiData.general_recommended && recommendedFlagComponent}
                </h4>

                <p className="map-card-link card-text">
                    <span className="mr-3"><FontAwesomeIcon className="details-fa-svg" icon={faStar} /></span>{getRatingReviewString(poiData)}
                </p>

                <p className="map-card-link card-text">
                    <span className="mr-3"><FontAwesomeIcon className="details-fa-svg" icon={faMapMarkerAlt} /></span>{poiData.general_address}
                </p>

                {!isNullOrUndefined(poiData.general_phone_number) && phoneNumberComponent}
            </div>

            <ul className="detail-tabs">
                <li
                    className={getDetailTabLiClassName(DetailTabEnum.INFO)}
                    onClick={() => handleClickDetailTab(DetailTabEnum.INFO)}
                >Info</li>
                <li
                    className={getDetailTabLiClassName(DetailTabEnum.GALLERY)}
                    onClick={() => handleClickDetailTab(DetailTabEnum.GALLERY)}
                >Gallery</li>
                <li
                    className={getDetailTabLiClassName(DetailTabEnum.REVIEWS)}
                    onClick={() => handleClickDetailTab(DetailTabEnum.REVIEWS)}
                >Reviews</li>
            </ul>

            {TabContentWrapper}

        </div>
    );
}

export default BobaDetails;
