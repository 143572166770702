import React from 'react';

import CaviarImg from "../images/logo_caviar.png";
import DoordashImg from "../images/logo_doordash.png";
import GrubhubImg from "../images/logo_grubhub.png";
import PostmatesImg from "../images/logo_postmates.png";
import UberEatsImg from "../images/logo_ubereats.png";

import { isNullOrUndefined } from './utils/util';


// Set it to true to see all delivery logos
const DEBUG = false;

const shouldDisplayDeliveryTab = ({
	delivery_caviar,
	delivery_doordash,
	delivery_grubhub,
	delivery_ubereats,
	delivery_postmates
}) => {
	return DEBUG || delivery_caviar || delivery_doordash || delivery_grubhub || delivery_postmates || delivery_ubereats;
}



// props.data = poiData.delivery
const DeliveryLinks = ({
	delivery_caviar,
	delivery_doordash,
	delivery_grubhub,
	delivery_ubereats,
	delivery_postmates
}) => {

	const deliveryComponent = (
		<div>
			{/* <div className="card-body pb-0">
				<h5 className="card-title text-center">Delivery</h5>
			</div> */}
			{/* <h5 className="card-title text-center">Delivery</h5> */}

            <div className="card-body pb-0">
                <h5 className="font-weight-bold text-uppercase">Delivery</h5>
            </div>

			<div className="card-body text-center">
				{deliveryBtn(delivery_caviar, "caviar", CaviarImg)}
				{deliveryBtn(delivery_doordash, "doordash", DoordashImg)}
				{deliveryBtn(delivery_grubhub, "grubhub", GrubhubImg)}
				{deliveryBtn(delivery_ubereats, "postmates", PostmatesImg)}
				{deliveryBtn(delivery_postmates, "ubereats", UberEatsImg)}
			</div>
		</div>);

	return (
		<div className="pb-2">
			{shouldDisplayDeliveryTab({
				delivery_caviar,
				delivery_doordash,
				delivery_grubhub,
				delivery_ubereats,
				delivery_postmates
			}) && deliveryComponent}
		</div>
	)
}

const deliveryBtn = (deliveryLink, description, logoImg) => {
	return (DEBUG || (deliveryLink && deliveryLink !== "")) &&
		<a
			href={deliveryLink}
			rel="noopener noreferrer"
			target="_blank"
		>
			<img
				src={logoImg}
				alt={description}
				className="delivery-img mx-2 shadow-sm bg-white"
			/>
		</a>
}

export default DeliveryLinks;
