import React from "react"

import Menu from "../components/menu";
import ReviewSubmissionPanel from "../components/review-submission";


const TestPage = () => {
	return (
		<div>
			<Menu />
			<ReviewSubmissionPanel />
		</div>
	)
}

export default TestPage;
