///////////////// Fake Fields Generators

// import TestBobaImg1 from "../../images/place_holder/test_boba_1.jpg";
// import TestBobaImg2 from "../../images/place_holder/test_boba_2.jpg";
// import TestBobaImg3 from "../../images/place_holder/test_boba_3.jpg";
// import TestBobaImg4 from "../../images/place_holder/test_boba_4.jpg";
// import NoImage from "../../images/boba_avatar/WB_Yoga.png";

// const BrokenImgLink = "";

// export const hashCodeToImg = (s) => {
//     const items = [TestBobaImg1, TestBobaImg2, TestBobaImg3, TestBobaImg4, NoImage];
//     return {
//         "resource_path": items[Math.floor(_seedRandom(_hashCodeToInt(s)) * items.length)],
//     };
// }

export const hashCodeToRecommendation = (s) => {
    return !(_seedRandom(_hashCodeToInt(s)) < 0.5);
}

export const hashCodeToPhoneNumber = (s) => {
    return _seedRandom(_hashCodeToInt(s)) < 0.5 ? "xxx-xxx-xxxx" : null;
}


// Function used to generate temp rating
export const hashCodeToRating = (s) => {
    // For simulation purpose, we want some places to have no rating
    if (_hashCodeToInt(s) % 3 == 0) return null;

    return ((_hashCodeToInt(s) % 10) + 1) / 2.0;
}

// Function used to generate temp number of reviews
export const hashCodeToNumOfReviews = (s) => {
    // For simulation purpose, we want some places to have no rating
    if (_hashCodeToInt(s) % 3 == 0) return null;

    return (_hashCodeToInt(s) % 200) + 1;
}

const _hashCodeToInt = (s) => {
    return Math.abs(_hashCode(s));
}

// Antti Sykäri's algorithm to generate a pseudo random number based on seed
const _seedRandom = function (s) {
    s = Math.sin(s) * 10000;
    return s - Math.floor(s);
};

// Gets the hascode of a string
const _hashCode = (s) => {
    var h = 0, l = s.length, i = 0;
    if (l > 0)
        while (i < l)
            h = (h << 5) - h + s.charCodeAt(i++) | 0;
    return h;
}

export const FerryBuildingCoordinate = [37.7955, -122.3937];


