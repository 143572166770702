import React, { Component } from 'react'

import {
    getSortByRatingFunction,
    getSortByTitleFunction,
    getApproxDistance,
    getSortByLocationFunction,
    getDistanceInMiles,
    Coordinate
} from "./utils/sort-util";
import BobaListItem from "./boba-list-item";
// import PoiJson from "../data/poi2.json";
import SortingPanel from "./sorting-panel";
import { initializeReactGA, getPosition, isNullOrUndefined } from './utils/util';
import { getBobaListRestCall, getStreetAddressGeoEncodingRestCall } from "./utils/rest-util";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSortAmountDown, faSortAmountUp, faTimes, faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core';

// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;

library.add(faSortAmountDown, faSortAmountUp, faTimes, faSlidersH);

const CURRENT_LOCATION = "current location";

class BobaList extends Component {
    constructor(props) {
        super(props)
        initializeReactGA('/boba-list');

        // If poiList is saved in user context, use poi list, otherwise use empty array
        var poiList = props.userContext.state.poiList || [];

        // sort all boba places by best overall ratings by default
        this.state = {
            searchValue: "", // Search value in the search input
            sortType: SORT_TYPE.TITLE_ASCENDING,
            // This is the original non filtered list
            originalPoiList: poiList,
            // This is the transformed filtered list for displaying
            visibleList: poiList,
            sortingPanelState: SORTING_PANEL_STATE.CLOSE, // by default hides the sorting panel
        };
        this.toggleSortingPanel = this.toggleSortingPanel.bind(this);
        this.handleSearchByName = this.handleSearchByName.bind(this);
        this.handleSearchByLocation = this.handleSearchByLocation.bind(this);
        this.sortVisibleList = this.sortVisibleList.bind(this);
        this.handleChangeSearchInput = this.handleChangeSearchInput.bind(this);

        // If no poi list saved need to fetch poi list and show loading panel
        if (!props.userContext.state.poiList) {
            this.props.userContext.setShowLoadingPanel(true);
        }
    }

    componentDidMount() {
        // Sort list by default type on start
        this.sortVisibleList(this.state.sortType);

        // Need to fetch poi list on start if no poi list saved
        if (!this.props.userContext.state.poiList) {
            this.fetchAndUpdateBobaList(this.props.userContext.state.searchCoordinate);
        }
    }

    fetchAndUpdateBobaList(coordinate) {
        console.log("fetchAndUpdateBobaList");
        // TODO: might want to check if coordinate is saved coordinate
        getBobaListRestCall(coordinate)
            .then((data) => {
                let poiList = data.pois;

                // If user coordiante exists, assign distance the list
                if (coordinate) {
                    this.addDistanceAttributeToPoiList(poiList, coordinate);
                }

                this.props.userContext.updatePoiList(poiList);

                this.setState({
                    originalPoiList: poiList,
                    visibleList: poiList
                });

                this.sortVisibleList(this.state.sortType);
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                this.props.userContext.setShowLoadingPanel(false);
            });
    }

    // input is a Coordinate object
    addDistanceAttributeToPoiList(poiList, coordinate) {
        poiList.forEach(poi => {
            let userLatLng = [coordinate.lat, coordinate.lng];
            let poiLatLng = [poi.geo_geometry_latitude, poi.geo_geometry_longitude];
            let distance = getDistanceInMiles(userLatLng, poiLatLng);
            // let distance = getDistanceInMiles(FerryBuildingCoordinate, poiLatLng);

            // Add distance property to each poi
            poi["distance"] = distance;
        })
    }

    toggleSortingPanel(sortingPanelState) {
        // console.log("toggleSortingPanel: " + sortingPanelState);
        this.setState({
            sortingPanelState: sortingPanelState
        })
    }

    // sortType must be type SORT_TYPE
    sortVisibleList(sortType) {
        // console.log("onSortButtonClicked: " + sortType);
        switch (sortType) {
            case SORT_TYPE.TITLE_ASCENDING:
                this.sortByTitleAscending();
                break;
            case SORT_TYPE.TITLE_DESCENDING:
                this.sortByTitleDescending();
                break;
            case SORT_TYPE.RATING:
                this.sortByRatingFunction();
                break;
            case SORT_TYPE.DISTANCE_CLOSEST:
                this.sortByDistanceClosest();
                break;
            default:
                console.error("sort type not supported");
        }

        this.setState({
            sortType: sortType,
        });

        this.toggleSortingPanel(SORTING_PANEL_STATE.CLOSE);

        // Always scroll back to top after sorting
        this.scrollToTop();
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    sortByRatingFunction() {
        this.state.visibleList.sort(getSortByRatingFunction(true));
    }

    sortByTitleAscending() {
        this.state.visibleList.sort(getSortByTitleFunction(true));
    }

    sortByTitleDescending() {
        this.state.visibleList.sort(getSortByTitleFunction(false));
    }

    sortByDistanceClosest() {
        this._sortByDistanceCallback(this.props.userContext.state.searchCoordinate, true);
    }

    // After receiving the user's location from the browser, 
    // sorts the visible list by distance.
    _sortByDistanceCallback(coordinate, sortAscending) {
        if (!coordinate) return;

        console.log("sort by distance done, selected location: ", coordinate);

        let userLatLng = [coordinate.lat, coordinate.lng];
        let distanceList = this.state.visibleList.map((poi, index) => {
            let poiLatLng = [poi.geo_geometry_latitude, poi.geo_geometry_longitude];
            let distance = getDistanceInMiles(userLatLng, poiLatLng);

            // Add distance property to each poi
            poi["distance"] = distance;
            return { index, distance };
        })
        distanceList.sort(getSortByLocationFunction(sortAscending));
        let newVisibleList = [];
        distanceList.forEach(item => {
            newVisibleList.push(this.state.visibleList[item.index]);
        });
        this.setState({
            visibleList: newVisibleList
        });
    }

    handleSearchByName(event) {
        // Only search when player presses enter key
        // if (event.keyCode == '13') { // Detect 'Enter' key
        let inputValue = event.target.value.trim();
        if (!isNullOrUndefined(inputValue) && inputValue !== "") {
            let searchValue = event.target.value.toLowerCase();
            // For now we search based on boba shop title and address
            let searchFunction = poi => {
                let itemTitle = poi.general_title.toLowerCase();
                let itemAddress = poi.general_address.toLowerCase();
                return itemTitle.includes(searchValue) ||
                    itemAddress.includes(searchValue);
            };
            this.filterVisibleList(searchFunction);
        } else {
            // If search value is empty, uses the original list
            this.setState({ visibleList: this.state.originalPoiList });
        }
        // }
    }

    handleSearchByLocation(event) {
        // Only search when player presses enter key
        if (event.keyCode == '13') { // Detect 'Enter' key
            let inputValue = event.target.value.trim();
            if (!isNullOrUndefined(inputValue) && inputValue !== "") {
                this.props.userContext.setShowLoadingPanel(true);
                if (inputValue === CURRENT_LOCATION) {
                    console.log("Get list based on current location");
                    // Get list based on current location     
                    getPosition()
                        .then((location) => {
                            let coordinate = new Coordinate(location.coords.latitude, location.coords.longitude);
                            this.props.userContext.updateSearchCoordinate(coordinate);
                            this.fetchAndUpdateBobaList(coordinate);

                            // Make sure to clear Search input
                            this.setState({ searchValue: "" });
                        })
                        .catch((err) => {
                            console.error(err.message);
                            this.props.userContext.setShowLoadingPanel(false);
                        });
                } else {
                    // Get list based on input location
                    console.log("Get list based on search value: " + inputValue);

                    this.handleGeoEncodingButtonClicked(inputValue);
                }
            } else {
                // If search value is empty, uses the original list
                this.setState({ visibleList: this.state.originalPoiList });
            }
        }
    }

    handleGeoEncodingButtonClicked(searchValue) {
        getStreetAddressGeoEncodingRestCall(searchValue)
            .then(features => {
                if (features.length == 0) {
                    alert("Search location not found");
                } else {
                    // Just use the first feature
                    const [lng, lat] = features[0].geometry.coordinates;
                    let coordinate = new Coordinate(lat, lng);
                    this.props.userContext.updateSearchCoordinate(coordinate);
                    this.fetchAndUpdateBobaList(coordinate);
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                this.props.userContext.setShowLoadingPanel(false);
            })
    }

    filterVisibleList(filterFunction) {
        this.setState({
            visibleList: this.state.originalPoiList.filter(filterFunction)
        });
        // After search filter, sort the result by the current sort type
        this.sortVisibleList(this.state.sortType);
    }

    handleChangeSearchInput(e) {
        this.setState({ searchValue: e.target.value });
    }

    render() {
        // Only render the visible list as the visible list is filtered, e.g. search
        const bobaListItems = this.state.visibleList.map((poi, index) => {
            return <div key={index}><BobaListItem poi={poi} /></div>
        });
        return (
            <div>
                <div id="search-bar-container">
                    <div className="d-flex align-items-center justify-content-center">
                        <input
                            list="location-search-options"
                            id="location-search-bar"
                            type="text"
                            className="search-bar form-control position-top mr-2"
                            placeholder="Near San Francisco, California"
                            onKeyUp={this.handleSearchByLocation}
                        />
                        <datalist id="location-search-options">
                            <option value={CURRENT_LOCATION} />
                        </datalist>

                        <input
                            id=""
                            type="text"
                            className="search-bar form-control position-top mr-2"
                            placeholder="Search"
                            value={this.state.searchValue}
                            onChange={this.handleChangeSearchInput}
                            onKeyUp={this.handleSearchByName}
                        />
                        <button
                            type="button"
                            className="sorting-panel-btn btn-custom btn btn-primary"
                            onClick={() => this.toggleSortingPanel(SORTING_PANEL_STATE.OPEN)} >
                            <FontAwesomeIcon className="sorting-panel-fa-svg" icon={faSlidersH} />
                        </button>
                    </div>
                </div>

                <div id="poi-list" className="list-group">
                    {bobaListItems}
                </div>

                {
                    (this.state.sortingPanelState === SORTING_PANEL_STATE.OPEN) &&
                    <SortingPanel
                        toggleSortingPanel={this.toggleSortingPanel}
                        onSortButtonClicked={this.sortVisibleList}
                        currentSortType={this.state.sortType}
                    />
                }
            </div>
        )
    }
}

export default BobaList;

export const SORT_TYPE = {
    RATING: "rating", // our own metric to determine quality
    OVERALL_RATING: "overall rating",
    DISTANCE_CLOSEST: "distance closest",
    TITLE_ASCENDING: "title ascending",
    TITLE_DESCENDING: "title descending",
}

export const SORTING_PANEL_STATE = {
    OPEN: "open",
    CLOSE: "close"
}

export const SORT_BUTTON_PARAMETERS = [
    {
        buttonText: "Highest Rating",
        sortType: SORT_TYPE.RATING
    },
    // {
    //     buttonText: "Highest Overall Rating",
    //     sortType: SORT_TYPE.OVERALL_RATING
    // },
    {
        buttonText: "Closest",
        sortType: SORT_TYPE.DISTANCE_CLOSEST
    },
    {
        buttonText: "Title",
        sortType: SORT_TYPE.TITLE_ASCENDING
    }
];

export const FILTER_BUTTON_PARAMETERS = [
    {
        buttonText: "Delivery",
        sortType: SORT_TYPE.OVERALL_RATING
    },
    {
        buttonText: "Recommended",
    }
];
