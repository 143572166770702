/*

This contains a list of all possible status enums used to check agains the response gotten from a rest call.
The rest call response should be in the form of:

response = {
    data : <response_data>,
    status: <response_status_enum>
}


*/

export const SUCCESS = "SUCCESS";

/* Status for end point "/api/review" */
export const REVIEW_STATUS = {
    // This error status is returned when user tries to add a second review, each user can only write one review per place
    ERROR_AREADY_REVIEWED: "ERROR_AREADY_REVIEWED",
    // This error status is returned when user has no permission to write reviews
    ERROR_PERMISSION_DENIED: "ERROR_PERMISSION_DENIED"
}