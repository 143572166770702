import React from 'react';

import { SORTING_PANEL_STATE, SORT_BUTTON_PARAMETERS } from "../components/boba-list";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core';

// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;


// toggleSortingPanel is bind to BobaList.toggleSortingPanel which toggles the sorting panel
// onSortButtonClicked is bind to BobaList.onSortButtonClicked which sorts the boba list
// currentSortType is bind to BobaList.state.sortType which is updated to whichever sort button is clicked
const SortingPanel = ({ toggleSortingPanel, onSortButtonClicked, currentSortType }) => {
    // param is a SORT_BUTTON_PARAMETERS list item
    const sortButtonComponent = SORT_BUTTON_PARAMETERS.map((param, index) => (
        <SortButton
            key={index}
            active={param.sortType === currentSortType}
            buttonText={param.buttonText}
            onSortButtonClickedCall={() => onSortButtonClicked(param.sortType)}
        />
    ));

    return (
        <div id="sorting-panel">

            <button
                type="button"
                className="btn ml-2 mt-2"
                onClick={() => toggleSortingPanel(SORTING_PANEL_STATE.CLOSE)} >
                <FontAwesomeIcon className="sorting-panel-fa-svg" icon={faTimes} />
            </button>

            <div>
                <div className="d-flex flex-column align-items-stretch px-3 py-3">
                    <h4>Sort results by</h4>
                    <div className="mt-1">
                        <div className="list-group list-group-flush">
                            {sortButtonComponent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

/* 
if active == true, then this button is highlighted, otherwise not highlighted
buttonText is the text displayed on the button
onSortButtonClickedCall wraps the sort button call
 */
const SortButton = ({ active, buttonText, onSortButtonClickedCall }) => {
    return (
        <button
            type="button"
            className={active ? "sort-button list-group-item list-group-item-action active" : "sort-button list-group-item list-group-item-action"}
            onClick={onSortButtonClickedCall}>
            {buttonText}
        </button>
    );
};

export default SortingPanel;