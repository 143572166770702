import React, { Component } from 'react';

import Menu from '../components/menu';
import Layout from '../components/layout';
import Footer from "../components/footer";
import BobaDetails from "../components/boba-details";
import { initializeReactGA } from '../components/utils/util';
import UserContext, { LOGIN_STATUS } from "../components/user-context";
import { getReviewsRestCall, getPlacePhotosRestCall, getPlaceRestCall } from "../components/utils/rest-util";
import { MOBILE_WIDTH } from "../components/constants";
import { SUCCESS } from '../components/utils/response-status';

const getInfo = (poiData, reviewsData, placePhotosData, reloadPoiDetailsAfterReviewSubmission, isMobile) => {
    if (poiData) {
        return (
            <div>
                <BobaDetails isMobile={isMobile} poiData={poiData} reviewsData={reviewsData} placePhotosData={placePhotosData} reloadPoiDetailsAfterReviewSubmission={reloadPoiDetailsAfterReviewSubmission} />
            </div>
        )
    } else {
        return (
            <div className="boba-details-footer-wrapper">
                <Footer></Footer>
            </div>
        )
    }
}

class StreetMapComponent extends Component {
    constructor(props) {
        super(props)
        initializeReactGA('/street-map');

        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
        this.reloadPoiDetailsAfterReviewSubmission = this.reloadPoiDetailsAfterReviewSubmission.bind(this);

        this.state = {
            width: (typeof window !== 'undefined') ? window.innerWidth : 0,
            poiData: this.props.poiData, // poiData is poi data comes from the list of poi data seached near by, this is mianly used to render markers
            reviewsData: null, // reviews are fetched when a marker is clicked, this is used to popular details page
            placePhotosData: null, // place photos are fetched when a marker is clicked, this is used to popular details page
            redirect: false // Used to handle url redirect when user clicks on a boba place
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);

        // Always reset window scroll
        this.resetWindowScroll()

        // If there is no selected geo data but there is a route param poi id, then this boba 
        // place is accessed through a link directly and not linked through a poi list item
        // which means we need to fetch the place details data and display it on the map
        // Note that routeParamPoiId is the poiId obtained from route path "/map/:poiId"
        if (!this.state.poiData) {
            this.getPoiDetails(this.props.routeParamPoiId);
        } else {
            this.getPoiDetails(this.state.poiData.uuid);
        }
    }

    refreshPage() {
        window.location.reload(false);
    }

    handleWindowSizeChange() {
        this.setState({ width: window.innerWidth });
    }

    resetWindowScroll() {
        // Reset the scroll
        if (window) {
            window.scrollTo(0, 0);
        }
    }

    reloadPoiDetailsAfterReviewSubmission() {
        this.getPoiDetails(this.state.poiData.uuid);
    }

    getPoiDetails(poiId) {
        this.resetWindowScroll();

        // Replace the footer element in side info div with boba place information

        getPlaceRestCall(poiId)
            .then(data => {
                if (data.status === SUCCESS) {
                    console.log(`fetched poi data for poi`, data);
                    this.setState({ poiData: data.poi });
                }
            })
            .catch(err => {
                console.error(err)
            });

        let authResponse = this.props.userContext.state.authResponse;
        if (authResponse) {
            getReviewsRestCall(poiId, authResponse.userID, authResponse.accessToken)
                .then(reviewsData => {
                    if (reviewsData.status === SUCCESS) {
                        console.log(`fetched reviews data for poi`, reviewsData);
                        this.setState({ reviewsData });
                    } else {
                        console.error("getReviewsRestCall data status is not success, it is ", reviewsData.status);
                    }
                })
                .catch(err => {
                    console.error(err)
                });
        } else {
            getReviewsRestCall(poiId)
                .then(reviewsData => {
                    if (reviewsData.status === SUCCESS) {
                        console.log(`fetched reviews data for poi`, reviewsData);
                        this.setState({ reviewsData });
                    } else {
                        console.error("getReviewsRestCall data status is not success, it is ", reviewsData.status);
                    }
                })
                .catch(err => {
                    console.error(err)
                });
        }

        getPlacePhotosRestCall(poiId)
            .then(data => {
                if (data.status === SUCCESS) {
                    console.log(`fetched place photo data for poi`);
                    this.setState({ placePhotosData: data });
                }
            })
            .catch(err => {
                console.error(err)
            });
    }

    render() {
        const width = this.state.width;
        const isMobile = width <= MOBILE_WIDTH;

        return (
            <div className="store-details-page">
                <Menu />
                <div className="layout-container content-container">
                    {getInfo(this.state.poiData, this.state.reviewsData, this.state.placePhotosData, this.reloadPoiDetailsAfterReviewSubmission, isMobile)}
                </div>
            </div>
        );
    }
}


/**
 * Go to this page:             
 * <Link to={{pathname: `/store/${poi.uuid}`}>
 */
const StoreDetailsPage = (props) => {
    return (
        <div>
            <UserContext.Consumer>
                {(userContext) => {
                    if (userContext.state.loginStatus === LOGIN_STATUS.FAILURE ||
                        userContext.state.loginStatus === LOGIN_STATUS.FETCHED_USER_SUCCESS) {
                        return <StreetMapComponent userContext={userContext} poiData={props.location.poi} routeParamPoiId={props.match.params.poiId} />;
                    }

                    // This happens when login status is NONE or SUCCESS, if NONE then we show a blank page while waiting for the user to get 
                    // login status, if SUCCESS, we show a blank page while waiting to get user data then login status would become FETCHED_USER_SUCCESS
                    return <div></div>
                }}
            </UserContext.Consumer>
        </div>
    )
}

export default StoreDetailsPage;