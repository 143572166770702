import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faHeartBroken, faCaretUp, faCaretDown, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';

import SeasonalFlavorIcon from "../../images/place_holder/badge-new-seasonal-512.png";
import MilkTeaIcon from "../../images/place_holder/Bubble_Tea_tapioca_pearl-07-512.png";
import FruitTeaIcon from "../../images/place_holder/Bubble_Tea_tapioca_pearl-01-512.png";
import SpecialtyDrinksIcon from "../../images/place_holder/Bubble_Tea_tapioca_pearl-12-512.png";
import FoodIcon from "../../images/place_holder/sandwich-512.png";


// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;

/*

menu_info = {
    best: {
        id: "menu item id",
        name: "oolong tea",
        like: 10,
        user_liked: true
    },
    worst: {
        id: "menu item id",
        name: "oolong tea",
        like: 10,
        user_liked: true
    },
    menu_items: [
        {
            id: "menu item id",
            name: "oolong tea",
            like: 10,
            user_liked: true
        }
    ]
}

*/
const MenuInfo = ({ menu_info }) => {

    // Fake menu info
    menu_info = {
        best: {
            id: "menu item id",
            name: "oolong tea",
            like: 10,
            dislike: 0,
            user_liked: true,
            user_disiked: false
        },
        worst: {
            id: "menu item id",
            name: "okinawa brown sugar milk tea",
            like: 0,
            dislike: 0,
            user_liked: false,
            user_disiked: true
        },
        menu_items: {
            seasonal_flavors: [
                {
                    id: "menu item id",
                    name: "head in the cloud",
                    like: 40,
                    dislike: 0,
                    user_liked: true,
                    user_disiked: false
                }
            ],
            milk_tea: [
                {
                    id: "menu item id",
                    name: "okinawa brown sugar milk tea",
                    like: 10,
                    dislike: 0,
                    user_liked: true,
                    user_disiked: false
                },
                {
                    id: "menu item id",
                    name: "house special boba tea",
                    like: 2,
                    dislike: 0,
                    user_liked: false,
                    user_disiked: false
                }
            ],
            fruit_tea: [
                {
                    id: "menu item id",
                    name: "oolong tea (super super super super super long name)",
                    like: 265,
                    dislike: 0,
                    user_liked: false,
                    user_disiked: false
                },
                {
                    id: "menu item id",
                    name: "peppermint honey black tea",
                    like: 3,
                    dislike: 0,
                    user_liked: true,
                    user_disiked: false
                }
            ],
            specialty_drinks: [
                {
                    id: "menu item id",
                    name: "strawberry matcha latte",
                    like: 0,
                    dislike: 0,
                    user_liked: false,
                    user_disiked: false
                }
            ],
            food: [
                {
                    id: "menu item id",
                    name: "popcorn chicken",
                    like: 0,
                    dislike: 0,
                    user_liked: false,
                    user_disiked: false
                }
            ]
        }
    }

    const SeasonalFlavorListItems = menu_info.menu_items.seasonal_flavors.map((item, index) => {
        return <MenuInfoItem key={index} item={item} iconSrc={SeasonalFlavorIcon} />
    });

    const MilkTeaListItems = menu_info.menu_items.milk_tea.map((item, index) => {
        return <MenuInfoItem key={index} item={item} iconSrc={MilkTeaIcon} />
    });

    const FruitTeaListItems = menu_info.menu_items.fruit_tea.map((item, index) => {
        return <MenuInfoItem key={index} item={item} iconSrc={FruitTeaIcon} />
    });

    const SpecialtyDrinksListItems = menu_info.menu_items.specialty_drinks.map((item, index) => {
        return <MenuInfoItem key={index} item={item} iconSrc={SpecialtyDrinksIcon} />
    });

    const FoodListItems = menu_info.menu_items.food.map((item, index) => {
        return <MenuInfoItem key={index} item={item} iconSrc={FoodIcon} />
    });

    return (
        <div>
            <div>
                <div className="card-body pb-0">
                    <h5 className="font-weight-bold text-uppercase">Best Rated</h5>
                </div>
                <div className="card-body">
                    <div id="boba-menu-list" className="list-group">
                        <MenuInfoItem item={menu_info.best} iconSrc={FruitTeaIcon} />
                    </div>
                </div>
            </div>

            <div>
                <div className="card-body pb-0">
                    <h5 className="font-weight-bold text-uppercase">Worst Rated</h5>
                </div>
                <div className="card-body">
                    <div id="boba-menu-list" className="list-group">
                        <MenuInfoItem item={menu_info.worst} iconSrc={MilkTeaIcon} />
                    </div>
                </div>
            </div>

            <div>
                <div className="card-body pb-0">
                    <h5 className="font-weight-bold text-uppercase">Seasonal Flavors</h5>
                </div>
                <div className="card-body">
                    <div id="boba-menu-list" className="list-group">
                        {SeasonalFlavorListItems}
                    </div>
                </div>
            </div>

            <div>
                <div className="card-body pb-0">
                    <h5 className="font-weight-bold text-uppercase">Milk Tea</h5>
                </div>
                <div className="card-body">
                    <div id="boba-menu-list" className="list-group">
                        {MilkTeaListItems}
                    </div>
                </div>
            </div>

            <div>
                <div className="card-body pb-0">
                    <h5 className="font-weight-bold text-uppercase">Fruit Tea</h5>
                </div>
                <div className="card-body">
                    <div id="boba-menu-list" className="list-group">
                        {FruitTeaListItems}
                    </div>
                </div>
            </div>

            <div>
                <div className="card-body pb-0">
                    <h5 className="font-weight-bold text-uppercase">specialty drinks</h5>
                </div>
                <div className="card-body">
                    <div id="boba-menu-list" className="list-group">
                        {SpecialtyDrinksListItems}
                    </div>
                </div>
            </div>

            <div>
                <div className="card-body pb-0">
                    <h5 className="font-weight-bold text-uppercase">Food</h5>
                </div>
                <div className="card-body">
                    <div id="boba-menu-list" className="list-group">
                        {FoodListItems}
                    </div>
                </div>
            </div>
        </div>
    )
}

const MenuInfoItem = ({ item, iconSrc }) => {


    const handleUpVoteMenuItem = () => {
        // User is not allowed to up vote more than once
        if (item.user_liked) return;

        // Prevent user from upvoting the second time while the UI updates
        item.user_liked = true;

        console.log("handleUpVoteMenuItem");
    }

    const style1 = (
        <div className="boba-menu-item-card bg-white rounded-lg shadow">

            <div className="boba-menu-item-thumbnail">
                <img className="rounded-lg" src={iconSrc} alt="" />
            </div>
            <div className="item-details align-self-center">
                <div className="boba-menu-list-item-name">{item.name}</div>
            </div>
            <div className="boba-menu-list-item-vote-container align-self-center">

                <div className="row boba-menu-list-item-vote-row">
                    <div className="col boba-menu-list-item-vote-col">
                        <div
                            className={item.user_liked ? "boba-menu-list-item-up-voted" : "text-muted"}
                            onClick={() => handleUpVoteMenuItem()}
                        >
                            <FontAwesomeIcon className="review-list-item-fa-svg" icon={faCaretUp} />
                        </div>
                    </div>
                    <div className="col boba-menu-list-item-vote-col">
                        <p
                            className={item.user_liked ? "boba-menu-list-item-up-voted" : "text-muted"}
                            onClick={() => handleUpVoteMenuItem()}
                        >
                            {item.like}
                        </p>
                    </div>

                    <div className="col boba-menu-list-item-vote-col">
                        <div
                            className={item.user_liked ? "boba-menu-list-item-up-voted" : "text-muted"}
                            onClick={() => handleUpVoteMenuItem()}
                        >
                            <FontAwesomeIcon className="review-list-item-fa-svg" icon={faCaretDown} />
                        </div>
                    </div>

                </div>


            </div>

        </div>
    )

    const style2 = (
        <div className="boba-menu-item-card bg-white rounded-lg shadow">
            <div className="boba-menu-item-thumbnail">
                <img className="rounded-lg" src={iconSrc} alt="" />
            </div>
            <div className="item-details align-self-center">
                <div className="boba-menu-list-item-name">{item.name}</div>
            </div>
            <div className="boba-menu-list-item-vote-container align-self-center">

                <div className="row">
                    <div className="boba-menu-list-item-vote-col-temp col">
                        <span className="mr-1"><FontAwesomeIcon className="review-list-item-fa-svg" icon={faCaretUp} /></span>

                        {/* <div
                            className={item.user_liked ? "boba-menu-list-item-up-voted" : "text-muted"}
                            onClick={() => handleUpVoteMenuItem()}
                        >
                            <span className="mr-1"><FontAwesomeIcon className="review-list-item-fa-svg" icon={faCaretUp} /></span>{item.like}
                        </div> */}
                    </div>
                    <div className="boba-menu-list-item-vote-col-temp col">
                        {item.like}

                        {/* <div
                            className={item.user_liked ? "boba-menu-list-item-up-voted" : "text-muted"}
                            onClick={() => handleUpVoteMenuItem()}
                        >
                            <span className="mr-1"><FontAwesomeIcon className="review-list-item-fa-svg" icon={faCaretUp} /></span>{item.like}
                        </div> */}
                    </div>
                    <div className="boba-menu-list-item-vote-col-temp col">
                        <span className="mr-1"><FontAwesomeIcon className="review-list-item-fa-svg" icon={faCaretDown} /></span>

                        {/* <div
                            className={item.user_liked ? "boba-menu-list-item-up-voted" : "text-muted"}
                            onClick={() => handleUpVoteMenuItem()}
                        >
                            <span className="mr-1"><FontAwesomeIcon className="review-list-item-fa-svg" icon={faCaretDown} /></span>{item.dislike}
                        </div> */}
                    </div>
                </div>

                {/* <div
                    className={item.user_liked ? "boba-menu-list-item-up-voted" : "text-muted"}
                    onClick={() => handleUpVoteMenuItem()}
                >
                    <span className="mr-1"><FontAwesomeIcon className="review-list-item-fa-svg" icon={faCaretUp} /></span>{item.like}
                </div>
                <div
                    className={item.user_liked ? "boba-menu-list-item-up-voted" : "text-muted"}
                    onClick={() => handleUpVoteMenuItem()}
                >
                    <span className="mr-1"><FontAwesomeIcon className="review-list-item-fa-svg" icon={faCaretDown} /></span>{item.dislike}
                </div> */}
            </div>
        </div>
    )

    const style3 = (
        <div className="boba-menu-item-card bg-white rounded-lg shadow">

            <div className="boba-menu-item-thumbnail">
                <img className="rounded-lg" src={iconSrc} alt="" />
            </div>
            <div className="item-details align-self-center">
                <div className="boba-menu-list-item-name">{item.name}</div>

                <div class="d-flex flex-row">
                    <div
                        className={item.user_liked ? "boba-menu-list-item-up-voted" : "text-muted"}
                        onClick={() => handleUpVoteMenuItem()}
                    >
                        <FontAwesomeIcon className="review-list-item-fa-svg mr-2" icon={faHeart} />
                    </div>
                    <div
                        className={item.user_liked ? "boba-menu-list-item-up-voted" : "text-muted"}
                        onClick={() => handleUpVoteMenuItem()}
                    >
                        {item.like}
                    </div>

                    <div
                        className={item.user_disiked ? "boba-menu-list-item-up-voted" : "text-muted"}
                        onClick={() => handleUpVoteMenuItem()}
                    >
                        <FontAwesomeIcon className="review-list-item-fa-svg ml-2" icon={faHeartBroken} />
                    </div>
                </div>
            </div>

        </div>
    )

    return (
        style3
    )
}

export default MenuInfo;