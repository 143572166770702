import React, { Component } from 'react';

import { Link, NavLink } from "react-router-dom";

import "../styles/menu.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';
import UserContext from "../components/user-context";

import DesireBobaImg from "../images/boba_avatar/WB_Drinking.png";
import ConstructorBobaImg from "../images/constructor_boba.png";

// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;

const MenuLinks = ({ menuStatus, closeMenu, userContext }) => {
    const adminAction = (
        <div className="menu-divider">
            <li><NavLink className="menu-item-li" to="/poi-submission-form" activeClassName="active-menu-item-li">Submit a boba location</NavLink></li>
        </div>
    );

    return (
        <div>
            {menuStatus && <div onClick={closeMenu} className={menuStatus} id="menu-background"></div>}
            <div className={menuStatus} id="menu">
                <div className="drawer-user-info">
                    {/* <h3 className="drawer-title">Bobacritic</h3> */}
                    <div className="user-thumbnail"><img src={DesireBobaImg} className="img-fluid" ></img></div>

                    <div className="user-details align-self-center">
                        <h5 className="user-name">{userContext.state.userName}</h5>
                    </div>
                </div>
                <ul>
                    <div className="menu-divider">
                        <li><NavLink exact={true} className="menu-item-li" to="/" activeClassName="active-menu-item-li">Boba shops</NavLink></li>
                        {/* <li><NavLink className="menu-item-li" to="/map" activeClassName="active-menu-item-li">Map</NavLink></li> */}
                        <li><NavLink className="menu-item-li" to="/about" activeClassName="active-menu-item-li">About</NavLink></li>
                        <li><NavLink className="menu-item-li" to="/login" activeClassName="active-menu-item-li">{userContext.userAuthenticated() ? "Logout" : "Login"}</NavLink></li>
                    </div>
                    {userContext.userAuthenticated() && adminAction}
                </ul>
            </div>
        </div>
    );
}

// This is the menu bar used only in street map page with only a hamberger icon without the background
const MenuWithOutBackground = ({ menuStatus, openMenu, closeMenu }) => {
    return (
        <div>
            <div>
                <div id="menu-clicker" onClick={openMenu}></div>
                <div id="hambmenu"><FontAwesomeIcon icon={faBars} /></div>
            </div>
            <UserContext.Consumer>
                {(userContext) => (
                    <MenuLinks menuStatus={menuStatus} closeMenu={closeMenu} userContext={userContext} />
                )}
            </UserContext.Consumer>
        </div>
    )
}

// This is the menu bar used in every other pages with a solid top bar, bobacritic text and logo
const MenuWithBackground = ({ menuStatus, openMenu, closeMenu }) => {
    return (
        <div>
            <div className="menubar-background">
                <div id="menu-clicker" onClick={openMenu}></div>
                <div id="hambmenu"><FontAwesomeIcon icon={faBars} /></div>

                <h4 className="title text-center text-capitalize font-weight-bold">Bobacritic</h4>

                <Link to="/">
                    <img
                        src={ConstructorBobaImg}
                        className="title-icon"
                        alt="Bobacritic logo"
                    />
                </Link>
            </div>
            <UserContext.Consumer>
                {(userContext) => (
                    <MenuLinks menuStatus={menuStatus} closeMenu={closeMenu} userContext={userContext} />
                )}
            </UserContext.Consumer>
        </div>
    )
}

// param: removeBackground - if true removes menu bar background
class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
        this._openMenu = this._openMenu.bind(this);
        this._closeMenu = this._closeMenu.bind(this);
        this._menuToggle = this._menuToggle.bind(this);
        // this._handleDocumentClick = this._handleDocumentClick.bind(this);
    }
    componentDidMount() {
        // document.addEventListener('click', this._handleDocumentClick, false);

        // When closing menu allow body scrolling
        // document.body.classList.remove("no-scroll");
    }
    componentWillUnmount() {
        document.removeEventListener('click', this._handleDocumentClick, false);
    }
    // _handleDocumentClick(e) {
    //     if (!this.refs.root.contains(e.target) && this.state.isOpen === true) {
    //         this.setState({
    //             isOpen: false
    //         });
    //     };
    // }
    _menuToggle(e) {
        e.stopPropagation();
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    _openMenu(e) {
        e.stopPropagation();

        // When opening menu prevent body from scrolling
        // document.body.classList.add("no-scroll");

        this.setState({
            isOpen: true
        });
    }

    _closeMenu(e) {
        e.stopPropagation();

        // When closing menu allow body scrolling
        // document.body.classList.remove("no-scroll");

        this.setState({
            isOpen: false
        });
    }

    render() {
        let menuStatus = this.state.isOpen ? 'isopen' : '';
        let menu = <MenuWithBackground menuStatus={menuStatus} openMenu={this._openMenu} closeMenu={this._closeMenu} />;
        let menuNoBackground = <MenuWithOutBackground menuStatus={menuStatus} openMenu={this._openMenu} closeMenu={this._closeMenu} />;

        return (
            <div ref="root">
                {this.props.removeBackground ? menuNoBackground : menu}
            </div>
        )
    }
}

export default Menu;
