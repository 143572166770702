import React from 'react';

import Layout from "../components/layout";

import ConstructorsBobaImg from "../images/constructors_boba.png";

const AboutPage = () => {
    return (
        <Layout>
            <div className="text-center p-4">
                <h6 className="pb-4">
                    We're just some folks that set out to change the world, to leave it better than we found it. But that was really hard so we decided to work on boba.
                    </h6>
                <img src={ConstructorsBobaImg} className="about-image" alt="constructor boba"></img>
            </div>
        </Layout>
    )
}

export default AboutPage;
