import React from 'react';
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCrown, faHeart, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { config } from '@fortawesome/fontawesome-svg-core';

import { shortenAddress, getRatingReviewString, isNullOrUndefined } from './utils/util';
import { getOpenOrClosedString, poiDataToWeeklyBusinessHours, getDayOfWeek } from './utils/hours-of-operation-util'

import DesireBobaImg from "../images/boba_avatar/WB_Desire.png";
import ConstructorBobaImg from "../images/constructor_boba.png";
import ThumbnailPlaceHolder from "../images/boba_avatar/WB_Yoga.png";

// Turn off autoAddCss for font awesome because there is an issue related to how
// font awesome loads css causing icons to resize on load.
config.autoAddCss = false;

// data = poiData, get rid of data and just pass in the coordinate instead
const BobaListItem = ({ poi }) => {

    let businessHours = poiDataToWeeklyBusinessHours(poi)[getDayOfWeek()];
    let openOrCloseString = getOpenOrClosedString(businessHours);

    // let distanceElement = poi.distance && <span className="item-distance text-secondary">{"~" + poi.distance + (poi.distance == 1 ? " mile" : " miles")}</span>;
    let distanceElement = !isNullOrUndefined(poi.distance) && <span className="item-distance text-secondary">{`~${poi.distance} mi`}</span>;

    const thumbnail_src = poi.general_thumbnail_link ? poi.general_thumbnail_link : ThumbnailPlaceHolder;

    const card = (
        <div className="item-container">

            {poi.general_recommended && <RecommendedFlag />}

            <Link to={{
                pathname: `/store/${poi.uuid}`,
                poi: poi
            }}>

                <div className="item-card bg-white rounded-lg">
                    <div className="item-thumbnail">
                        <img className="rounded-lg" src={thumbnail_src} alt="" />
                    </div>
                    <div className="item-details align-self-center">
                        <p className="item-title">{poi.general_title}</p>

                        <div className="item-address text-secondary">
                            <span className="mr-1"><FontAwesomeIcon className="list-item-fa-svg" icon={faStar} /></span>{getRatingReviewString(poi)}
                            {distanceElement}
                        </div>

                        <p className="item-address text-secondary">
                            <span className="mr-1"><FontAwesomeIcon className="list-item-fa-svg" icon={faMapMarkerAlt} /></span>{shortenAddress(poi.general_address)}
                        </p>

                        {/* <p className="item-tags text-secondary">boardgame, eco-friendly</p> */}
                        <p className="item-hours text-secondary">
                            <span className="font-weight-bold ">{openOrCloseString}: </span> {businessHours.formattedString}
                        </p>
                    </div>
                </div>

            </Link>
        </div>
    );

    return (
        card
    )
}


const RecommendedFlag = () => {
    return (
        <div className="recommended-fa-container shadow">
            <FontAwesomeIcon className="recommended-fa-svg" icon={faCrown} />
        </div>
    )
}

export default BobaListItem;
