import React, { Component } from 'react';
import axios from 'axios';

import Layout from "../components/layout";
import UserContext from "../components/user-context";
import { initializeReactGA, isBlank, compressImage } from "../components/utils/util";
import { postCreatePlace, getStreetAddressGeoEncodingRestCall } from "../components/utils/rest-util";


const initialState = {
    // This is set to true once people successfull submited a poi
    poi_successfully_submitted: false,
    poi_submitted_message: "",


    /*====== Used for sarching address ======*/
    // street_address: "2815 California St",
    // city: "SF",
    // state: "CA",
    // zip_code: "94115",
    street_address: "",
    city: "",
    state: "",
    zip_code: "",
    found_address: "",


    /*====== Used for poi submission POST end point ======*/
    general_title: "",
    geo_geometry_latitude: "",
    geo_geometry_longitude: "",
    general_address: "",
    general_phone_number: "",
    general_description: "",
    // We only support uploading three images at the moment
    thumbnail_file: null,
    second_image_file: null,
    third_image_file: null,
};


class PoiSubmissionComponent extends Component {
    constructor(props) {
        super(props)
        initializeReactGA('/poi-submission-page');

        this.state = initialState;

        this.handleGeoEncodingButtonClicked = this.handleGeoEncodingButtonClicked.bind(this);

        this.handleChangeGeneralTitle = this.handleChangeGeneralTitle.bind(this);
        this.handleChangeLatitude = this.handleChangeLatitude.bind(this);
        this.handleChangeLongitude = this.handleChangeLongitude.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeStreetAddress = this.handleChangeStreetAddress.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleChangeZipCode = this.handleChangeZipCode.bind(this);
        this.handleChangeGeneralPhoneNumber = this.handleChangeGeneralPhoneNumber.bind(this);
        this.handleChangeGeneralDescription = this.handleChangeGeneralDescription.bind(this);

        this.handleSelectThumbnailFile = this.handleSelectThumbnailFile.bind(this);
        this.handleSelectSecondImageFile = this.handleSelectSecondImageFile.bind(this);
        this.handleSelectThirdImageFile = this.handleSelectThirdImageFile.bind(this);

        this.handleSubmitAnotherPlace = this.handleSubmitAnotherPlace.bind(this);
    }

    handleChangeGeneralTitle(event) {
        this.setState({ general_title: event.target.value });
    }

    handleChangeStreetAddress(event) {
        this.setState({ street_address: event.target.value });
    }

    handleChangeCity(event) {
        this.setState({ city: event.target.value });
    }


    handleChangeState(event) {
        this.setState({ state: event.target.value });
    }

    handleChangeZipCode(event) {
        this.setState({ zip_code: event.target.value });
    }

    handleChangeLatitude(event) {
        this.setState({ geo_geometry_latitude: event.target.value });
    }

    handleChangeLongitude(event) {
        this.setState({ geo_geometry_longitude: event.target.value });
    }

    handleChangeGeneralPhoneNumber(event) {
        this.setState({ general_phone_number: event.target.value });
    }

    handleChangeGeneralDescription(event) {
        this.setState({ general_description: event.target.value });
    }

    handleSelectThumbnailFile(event) {
        if (event.target.files && event.target.files[0]) {
            // console.log("Thumbnail selected: " + event.target.files[0].name);
            this.setState({ thumbnail_file: event.target.files[0] })
        } else {
            // Handle set image canceled
            this.setState({ thumbnail_file: null })
        }
    }

    handleSelectSecondImageFile(event) {
        if (event.target.files && event.target.files[0]) {
            // console.log("Second image selected: " + event.target.files[0].name);
            this.setState({ second_image_file: event.target.files[0] })
        } else {
            // Handle set image canceled
            this.setState({ second_image_file: null })
        }
    }

    handleSelectThirdImageFile(event) {
        if (event.target.files && event.target.files[0]) {
            // console.log("Third image selected: " + event.target.files[0].name);
            this.setState({ third_image_file: event.target.files[0] })
        } else {
            // Handle set image canceled
            this.setState({ third_image_file: null })
        }
    }

    handleGeoEncodingButtonClicked() {
        this.props.userContext.setShowLoadingPanel(true);

        const generalAddress = this.makeGeneralAddress();

        getStreetAddressGeoEncodingRestCall(generalAddress)
            .then(features => {
                if (features.length === 0) {
                    alert("Street address does not exist");
                } else {
                    const [lng, lat] = features[0].geometry.coordinates;
                    let foundAddress = features[0].properties.display_name;
                    console.log("open street map geo encoding: ", lat, lng, " address: ");
                    this.setState({
                        found_address: foundAddress,
                        geo_geometry_latitude: lat,
                        geo_geometry_longitude: lng
                    });
                }
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                this.props.userContext.setShowLoadingPanel(false);
            });
    }

    makeGeneralAddress() {
        return `${this.state.street_address}, ${this.state.city}, ${this.state.state} ${this.state.zip_code}`;
    }

    _poiSubmissionFailedMessage(reason) {
        return `Poi submission failed, please try again. Reason: ${reason}`;
    }

    handleSubmitAnotherPlace() {
        this.setState(initialState);
    }

    handleSubmit = userContext => event => {
        event.preventDefault();

        if (!userContext.state.authResponse) {
            return;
        }

        if (!this._fieldsAreValid()) {
            console.error("fields aren't valid");
            this.setState({ poi_submitted_message: this._poiSubmissionFailedMessage("some of your your form fields are not valid") });
            return;
        }

        this.props.userContext.setShowLoadingPanel(true);

        this._tryCompressImagesThenUploadData(userContext);
    }

    _tryCompressImagesThenUploadData(userContext) {
        let imageFiles = [];
        if (this.state.thumbnail_file) {
            imageFiles.push(this.state.thumbnail_file);
        }
        if (this.state.second_image_file) {
            imageFiles.push(this.state.second_image_file);
        }
        if (this.state.third_image_file) {
            imageFiles.push(this.state.third_image_file);
        }

        if (imageFiles.length === 0) {
            this._uploadData(userContext, []);
        } else {
            let self = this;
            Promise.all(imageFiles.map(i => compressImage(i)))
                .then((compressedFiles) => {
                    self._uploadData(userContext, compressedFiles);
                })
                .catch((error) => {
                    console.error(error);
                    this.props.userContext.setShowLoadingPanel(false);
                });
        }
    }

    _uploadData(userContext, imageFilesToUpload) {
        const formData = this._constructFormData(imageFilesToUpload);
        const accessToken = userContext.state.authResponse.accessToken;
        const userID = userContext.state.authResponse.userID;

        postCreatePlace(formData, userID, accessToken)
            .then(res => {
                // need to reset poi list to null whenever a new place is submitted, this will trigger a get request to fetch new lists
                this.props.userContext.resetPoiList();
                this.setState({ poi_successfully_submitted: true });
                this.setState({ poi_submitted_message: "Poi submission succeeded!" });
            })
            .catch(err => {
                console.error(err)
                this.setState({ poi_submitted_message: this._poiSubmissionFailedMessage(err) });
            })
            .finally(() => {
                this.props.userContext.setShowLoadingPanel(false);
            })
    }

    _constructFormData(imageFiles) {
        const formData = new FormData();

        if (imageFiles.length > 0) {
            // Even if thumbnail file is empty, but if there are other images use the first as the thumbnail image
            formData.append("thumbnail_filename", imageFiles[0].name);
            formData.append("place_photos", imageFiles[0])
            // Use rest of the images as gallery photo
            for (let i = 1; i < imageFiles.length; i++) {
                formData.append("place_photos", imageFiles[i])
            }
        }

        /*
         * If more than 1 featrues are found that means we probably can't find the exact street address,
         * e.g. search "714 Kearny St, San Francisco, CA 94111" gives you "Kearny Street, Chinatown, San Francisco, California, 90104, United States of America"
         * so we need to use user constructed address
         */

        // Add place data info
        formData.append("place_data", JSON.stringify({
            general_title: this.state.general_title,
            general_address: this.makeGeneralAddress(),
            geo_geometry_latitude: this.state.geo_geometry_latitude,
            geo_geometry_longitude: this.state.geo_geometry_longitude,
            general_phone_number: this.state.general_phone_number,
            general_description: this.state.general_description,
        }));

        return formData;
    }

    // Minimum amount of fileds requried to submit a boba location
    _fieldsAreValid() {
        // https://stackoverflow.com/questions/22903756/using-regular-expression-to-validate-latitude-and-longitude-coordinates-then-dis
        const latRegex = /^-?([1-8]?[1-9]|[1-9]0)\.{1}\d{1,15}/g;
        const lngRegex = /^-?(([-+]?)([\d]{1,3})((\.)(\d+))?)/g;
        return !isBlank(this.state.general_title)
            && !isBlank(this.state.found_address)
            && !isBlank(this.state.street_address)
            && !isBlank(this.state.city)
            && !isBlank(this.state.state)
            && !isBlank(this.state.zip_code)
            && !isBlank(this.state.geo_geometry_latitude)
            && !isBlank(this.state.geo_geometry_longitude)
            && latRegex.exec(this.state.geo_geometry_latitude)
            && lngRegex.exec(this.state.geo_geometry_longitude);
    }

    _getFileNameForInputLabel(file) {
        return (file && file.name) ? file.name : "Choose file"
    }

    render() {
        const submissionSucceededMessage = (
            <div className="p-4">
                <div className="pt-3 text-center">
                    <h5>{this.state.poi_submitted_message}</h5>
                </div>

                <div className="d-flex justify-content-center mb-3">
                    <button
                        type="button"
                        className="poi-submission-form-btn btn btn-outline-primary mr-2"
                        onClick={this.handleSubmitAnotherPlace} >
                        Submit Another Boba Place</button>
                </div>

            </div>
        );

        const submissionForm = (
            <form onSubmit={this.handleSubmit(this.props.userContext)} className="p-4">

                <h5>General Info</h5>
                <div className="form-group">
                    <label htmlFor="title">Boba shop name</label>
                    <input
                        type="text" className="form-control" placeholder=""
                        value={this.state.general_title}
                        onChange={this.handleChangeGeneralTitle}
                        required />
                    <small className="form-text text-muted">This field is required</small>
                </div>

                <div className="form-group">
                    <label htmlFor="title">Phone number</label>
                    <input
                        type="text" className="form-control" placeholder="xxx-xxx-xxxx"
                        onChange={this.handleChangeGeneralPhoneNumber} />
                </div>

                <div className="form-group">
                    <label htmlFor="title">Description</label>
                    <input
                        type="text" className="form-control" placeholder=""
                        onChange={this.handleChangeGeneralDescription} />
                </div>

                <h5>Boba Shop Address</h5>
                <div className="form-row">
                    <div className="form-group col-md-4">
                        <label htmlFor="address">Street address</label>
                        <input
                            type="text" className="form-control" placeholder=""
                            value={this.state.street_address}
                            onChange={this.handleChangeStreetAddress}
                            required />
                        <small className="form-text text-muted">This field is required</small>
                    </div>
                    <div className="form-group col-md-4">
                        <label htmlFor="address">City</label>
                        <input
                            type="text" className="form-control" placeholder=""
                            value={this.state.city}
                            onChange={this.handleChangeCity}
                            required />
                        <small className="form-text text-muted">This field is required</small>
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="address">State</label>
                        <input
                            type="text" className="form-control" placeholder=""
                            value={this.state.state}
                            onChange={this.handleChangeState}
                            required />
                        <small className="form-text text-muted">This field is required</small>
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="address">Zip Code</label>
                        <input
                            type="text" className="form-control" placeholder=""
                            value={this.state.zip_code}
                            onChange={this.handleChangeZipCode}
                            required />
                        <small className="form-text text-muted">This field is required</small>
                    </div>
                </div>

                <div className="mb-3">
                    <div className="d-flex justify-content-center">
                        <button
                            type="button"
                            className="poi-submission-form-btn btn btn-outline-primary mr-2"
                            onClick={this.handleGeoEncodingButtonClicked} >
                            Location Search
                    </button>
                    </div>
                    <small className="form-text text-muted">This step is required</small>
                    <small>
                        <a target="_blank" href="https://www.latlong.net/convert-address-to-lat-long.html">
                            Open coordinate conversion tool
                        </a>
                    </small>
                </div>

                <div className="form-group">
                    <label htmlFor="latitude">Address Found</label>
                    <div>{this.state.found_address}</div>
                    <small className="form-text text-muted">This field is required, please click on location search</small>
                </div>

                {/* <h5>Address Coordinate</h5> */}
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="latitude">Latitude</label>
                        <input
                            type="text" className="form-control" placeholder=""
                            value={this.state.geo_geometry_latitude}
                            onChange={this.handleChangeLatitude}
                            required />
                        <small className="form-text text-muted">This field is required, please click on location search</small>
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="latitude">Longitude</label>
                        <input
                            type="text" className="form-control" placeholder=""
                            value={this.state.geo_geometry_longitude}
                            onChange={this.handleChangeLongitude}
                            required />
                        <small className="form-text text-muted">This field is required, please click on location search</small>
                    </div>
                </div>

                <h5>Logo/Thumbnail Photo</h5>
                <div className="d-flex justify-content-center mb-3">
                    <div className="custom-file">
                        <input
                            type="file"
                            accept="image/*"
                            className="custom-file-input"
                            id="thumbnailFile"
                            onChange={this.handleSelectThumbnailFile} />
                        <label className="custom-file-label" htmlFor="thumbnailFile">{this._getFileNameForInputLabel(this.state.thumbnail_file)}</label>
                    </div>
                </div>

                <h5>Gallery Photos</h5>
                <p className="form-text text-muted">Only two gallery photos are allowed currently</p>
                <div className="d-flex justify-content-center mb-3">
                    <div className="custom-file">
                        <input
                            type="file"
                            accept="image/*"
                            className="custom-file-input"
                            id="secondImageFile"
                            onChange={this.handleSelectSecondImageFile} />
                        <label className="custom-file-label" htmlFor="secondImageFile">{this._getFileNameForInputLabel(this.state.second_image_file)}</label>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-3">
                    <div className="custom-file">
                        <input
                            type="file"
                            accept="image/*"
                            className="custom-file-input"
                            id="thirdImageFile"
                            onChange={this.handleSelectThirdImageFile} />
                        <label className="custom-file-label" htmlFor="thirdImageFile">{this._getFileNameForInputLabel(this.state.third_image_file)}</label>
                    </div>
                </div>

                <div className="d-flex justify-content-center">
                    <button
                        type="submit"
                        className="poi-submission-form-btn btn-custom btn btn-primary" >
                        Submit</button>
                </div>

                <div className="error-message pt-3 text-center">
                    <h5>{this.state.poi_submitted_message}</h5>
                </div>

            </form>
        );

        return (
            <div>
                {this.state.poi_successfully_submitted ? submissionSucceededMessage : submissionForm}
            </div>
        )
    }
}

const PoiSubmissionForm = () => {
    return (
        <div>
            <Layout>
                <UserContext.Consumer>
                    {(userContext) => (
                        <PoiSubmissionComponent userContext={userContext} />
                    )}
                </UserContext.Consumer>
            </Layout>
        </div>
    )
}

export default PoiSubmissionForm;